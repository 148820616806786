import React from 'react';

import { Button } from '../Button/button';
import { ButtonGroup } from '../Button/button.group';

interface IModalActionsProps {
  onCancel(): void;
  onConfirm(): void;
  submitButtonLabel: string;
}

export const ModalActions = ({
  onCancel,
  onConfirm,
  submitButtonLabel,
}: IModalActionsProps): JSX.Element => {
  return (
    <ButtonGroup className="bg-gray-50 px-4 py-3 sm:px-6" isReverse>
      <Button onClick={onConfirm} type="submit" accentColor="red">
        {submitButtonLabel}
      </Button>
      <Button onClick={onCancel} type="reset" accentColor="plain">
        Cancel
      </Button>
    </ButtonGroup>
  );
};
