import React from 'react';

import { useSettings } from '../../Context/settings/settingsContext';
import {
  CONNECTION_STATUS,
  SET_CONNECTION_STATUS,
} from '../../Context/settings/settingsContext.constants';
import {
  useSocketSocket,
  AUTHENTICATE_RACE,
  useEmit,
} from '../../Context/socketContext';
import {
  EmitableActionsType,
  IUnverifiedSocketMessage,
} from '../../Context/socketContext.types';
import { ShowFailedMessages } from '../../pages/ShowFailedMessages/ShowFailedMessages';

const mapSocketStatusMessage = {
  [CONNECTION_STATUS.CONNECTED]: 'yhdistetty',
  [CONNECTION_STATUS.WAITING]: 'yhdistetään...',
  [CONNECTION_STATUS.FAILED]: 'katkennut',
};

export const ShowFailedMessagesContainer = (): JSX.Element => {
  const removeDuplicates = <T extends unknown>(array: T[]): T[] =>
    Array.from(new Set(array));
  const mapMessagesToObjectByTypes = (
    types: EmitableActionsType['type'][],
    data: IUnverifiedSocketMessage[]
  ) =>
    types.reduce((prev, type) => {
      const newValue = { ...prev };
      newValue[type] = data.filter(({ value }) => value.type === type);
      return newValue;
    }, {} as { [key in EmitableActionsType['type']]: IUnverifiedSocketMessage[] });

  const emit = useEmit();
  const [{ unverifiedMessages }, dispatchSocket] = useSocketSocket();
  const [{ connectionStatus, raceToken }, dispatchSettings] = useSettings();
  const isSocketActive = connectionStatus === CONNECTION_STATUS.CONNECTED;
  const isSocketReconnecting = connectionStatus === CONNECTION_STATUS.WAITING;

  const failedTypes = removeDuplicates(
    unverifiedMessages.map((unverifiedMessage) => unverifiedMessage.value.type)
  );

  const onConnected = () =>
    dispatchSettings({
      type: SET_CONNECTION_STATUS,
      payload: CONNECTION_STATUS.CONNECTED,
    });

  const reconnectSocket = () => {
    dispatchSocket({
      type: AUTHENTICATE_RACE,
      payload: raceToken || '',
      onConnected,
    });
  };

  const resendMessage = (action: EmitableActionsType) => emit(action);
  const resendAllMessages = () =>
    unverifiedMessages.forEach(({ value: action }) => emit(action));

  return (
    <ShowFailedMessages
      reconnectSocket={reconnectSocket}
      isSocketActive={isSocketActive}
      isSocketReconnecting={isSocketReconnecting}
      failedMessages={mapMessagesToObjectByTypes(
        failedTypes,
        unverifiedMessages
      )}
      resendMessage={resendMessage}
      resendAllMessages={resendAllMessages}
      socketStateMessage={
        mapSocketStatusMessage[connectionStatus || CONNECTION_STATUS.FAILED]
      }
    />
  );
};
