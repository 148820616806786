import React, { useEffect } from 'react';

import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useSetTimeout } from '../../hooks/useSetTimeout';
import { useTimeToStart } from '../../pages/Start/useTimeToStart';
import { parseCompetitorOverallStatus } from '../../services/competitorStatus';

interface CompetitorStatusProps {
  starttime: number;
  status: CompetitorStatusType;
  finishtime?: number;
}

export const CompetitorStatus = ({
  starttime,
  status,
  finishtime,
}: CompetitorStatusProps): JSX.Element => {
  const getTimeToStart = useTimeToStart();
  const [forceUpdate] = useForceUpdate();
  const [startTimeout] = useSetTimeout();
  useEffect(() => {
    const timeToStart = getTimeToStart(starttime);
    if (timeToStart > 0) {
      startTimeout(forceUpdate, timeToStart);
    }
  }, [forceUpdate, getTimeToStart, startTimeout, starttime]);

  const competitorStatus = parseCompetitorOverallStatus(
    starttime,
    status,
    finishtime
  );

  return <>{competitorStatus}</>;
};
