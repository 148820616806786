import { ICompetitorApiResponse } from '../types/apiResponse';

const timeZoneOffsetMs = new Date().getTimezoneOffset() * -1 * 60 * 1000;

const COMPETITOR_IN_GROUP =
  process.env.REACT_APP_TEST_COMPETITORS !== undefined
    ? parseInt(process.env.REACT_APP_TEST_COMPETITORS, 10)
    : 150;
const TIME_BETWEEN_STARTS =
  process.env.REACT_APP_TEST_TIME_BETWEEN_STARTS !== undefined
    ? parseInt(process.env.REACT_APP_TEST_TIME_BETWEEN_STARTS, 10)
    : 15000;

const lastNames = [
  'Korhonen',
  'Virtanen',
  'Mäkinen',
  'Nieminen',
  'Mäkelä',
  'Hämäläinen',
  'Laine',
  'Heikkinen',
  'Koskinen',
  'Järvinen',
  'Lehtonen',
  'Lehtinen',
  'Saarinen',
  'Salminen',
  'Heinonen',
  'Niemi',
  'Heikkilä',
  'Kinnunen',
  'Salonen',
  'Turunen',
];

const firstNames = {
  men: [
    'Juhani',
    'Johannes',
    'Olavi',
    'Antero',
    'Tapani',
    'Kalevi',
    'Tapio',
    'Matti',
    'Mikael',
    'Ilmari',
  ],
  women: [
    'Maria',
    'Liisa',
    'Johanna',
    'Kaarina',
    'Marjatta',
    'Anna',
    'Anneli',
    'Annikki',
    'Sofia',
  ],
};

const teams = [
  '',
  'Vantaan hiihtoseura',
  'Espoon hiihtoseura',
  'Pakilan veto',
  'Lahden hiihtoseura',
  'Saarijärven pullistus',
];

const getRandNum = (max: number, min = 0) =>
  Math.floor(Math.random() * (max - min)) + min;

const getRandomItem = <T extends unknown>(arr: T[]): T =>
  arr[getRandNum(arr.length - 1)];

const getTeam = () => getRandomItem(teams);
const getLastname = () => getRandomItem(lastNames);
const getMale = () => `${getRandomItem(firstNames.men)} ${getLastname()}`;
const getFemale = () => `${getRandomItem(firstNames.women)} ${getLastname()}`;

export const getList = (): ICompetitorApiResponse[] => {
  const data = [];
  let bib = 0;
  let start =
    Math.ceil((Date.now() + timeZoneOffsetMs) / 30000) * 30000 -
    TIME_BETWEEN_STARTS * COMPETITOR_IN_GROUP;

  for (let i = 0; i <= COMPETITOR_IN_GROUP; i += 1) {
    bib += 1;
    start += TIME_BETWEEN_STARTS;
    data.push({
      name: getFemale(),
      starttime: start,
      category: 'NYL',
      finishtime: 0,
      team: getTeam(),
      status: '' as CompetitorStatusType,
      bib: bib.toString(),
    });
  }

  start += 120000;

  for (let i = 0; i <= COMPETITOR_IN_GROUP; i += 1) {
    bib += 1;
    start += TIME_BETWEEN_STARTS;
    data.push({
      name: getFemale(),
      starttime: start,
      category: 'NYL',
      finishtime: 0,
      team: getTeam(),
      status: '' as CompetitorStatusType,
      bib: bib.toString(),
    });
  }
  for (let i = 0; i <= COMPETITOR_IN_GROUP; i += 1) {
    bib += 1;
    data.push({
      name: getFemale(),
      starttime: Date.now(),
      category: 'NYL',
      finishtime: Date.now() + getRandNum(18000, 15000) * 100,
      team: getTeam(),
      status: '' as CompetitorStatusType,
      bib: bib.toString(),
    });
  }
  start += 120000;

  for (let i = 0; i <= COMPETITOR_IN_GROUP; i += 1) {
    bib += 1;
    start += TIME_BETWEEN_STARTS;
    data.push({
      name: getMale(),
      starttime: Date.now(),
      category: 'MYL',
      finishtime: 0,
      team: getTeam(),
      status: '' as CompetitorStatusType,
      bib: bib.toString(),
    });
  }
  for (let i = 0; i <= COMPETITOR_IN_GROUP; i += 1) {
    bib += 1;
    data.push({
      name: getMale(),
      starttime: Date.now(),
      category: 'MYL',
      finishtime: Date.now() + getRandNum(30000, 18000) * 100,
      team: getTeam(),
      status: '' as CompetitorStatusType,
      bib: bib.toString(),
    });
  }
  return data;
};
