import React from 'react';

import { useSetTimeout } from '../../hooks/useSetTimeout';

interface ILinkLongPressProps {
  pressDelay?: number;
  onLongPress(): void;
  children: React.ReactNode;
}

export const LinkLongPress = ({
  pressDelay = 5000,
  onLongPress,
  children,
}: ILinkLongPressProps): JSX.Element => {
  const [startTimeout, removeTimeout] = useSetTimeout();

  const handlePress = () => {
    startTimeout(() => onLongPress(), pressDelay);
  };

  const handleRelease = () => {
    removeTimeout();
  };

  return (
    <i
      role="link"
      onTouchStart={handlePress}
      onTouchEnd={handleRelease}
      onMouseDown={handlePress}
      onMouseUp={handleRelease}
      onMouseLeave={handleRelease}
    >
      {children}
    </i>
  );
};
