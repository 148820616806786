import { useLocation } from 'react-router-dom';

import { useTeamInfoContext } from '../Context/teamInfoContext';

const TEAM_ID = parseInt(process.env.REACT_APP_TEAM_ID || '', 10);

export const useTeamIdQueryString = (): string => {
  const { search } = useLocation();
  const { teamId } = useTeamInfoContext();

  const urlSearchParams = new URLSearchParams(search);
  if (teamId !== TEAM_ID) {
    urlSearchParams.set('teamId', teamId.toString());
  } else {
    urlSearchParams.delete('teamId');
  }

  return urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '';
};
