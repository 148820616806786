import React from 'react';

import { Button } from '../Button/button';

interface IModalProps {
  toggleButtonClassName?: string;
  accentColor?: 'red' | 'green' | 'brand';
  children: React.ReactNode;
  isOpen: boolean;
  modalOpenButtonLabel: string;
  toggleOpen(): void;
}

export const Modal = ({
  toggleButtonClassName = '',
  children,
  isOpen,
  modalOpenButtonLabel,
  toggleOpen,
  accentColor,
}: IModalProps): JSX.Element => {
  return (
    <>
      <Button
        accentColor={accentColor}
        onClick={toggleOpen}
        className={toggleButtonClassName}
      >
        {modalOpenButtonLabel}
      </Button>
      {isOpen && (
        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
            &#8203;
            <div
              className="inline-block w-full max-w-lg overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
