import React, { useState, useEffect } from 'react';

import { useRaceInfoContext } from '../Context/raceInfoContext';
import { CompetitorList } from '../pages/CompetitorList/CompetitorList';
import { fetchCompetitorList } from '../services/apiService';

export const CompetitorListContainer = (): JSX.Element => {
  const [raceInfo] = useRaceInfoContext();
  const [competitorList, setCompetitorList] = useState<
    ICompetitorListCompetitor[] | null
  >(null);

  useEffect(() => {
    if (!raceInfo?.id) return;

    const getCompetitorList = async () => {
      const newCompetitorList = await fetchCompetitorList(raceInfo.id);
      setCompetitorList(newCompetitorList || []);
    };

    getCompetitorList();
  }, [raceInfo?.id]);

  return (
    <CompetitorList
      competitorList={competitorList}
      raceName={raceInfo?.name || ''}
    />
  );
};
