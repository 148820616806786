export const selectToClibboard = (
  element: HTMLTextAreaElement | null
): void => {
  if (!element) {
    console.log('Failed to copy, because source element was NULL.');
    return;
  }
  element.select();
  document.execCommand('copy');
};

export const addLeadingZero = (
  number: number | string,
  amountOfNumbers = 2
): string => `000${number}`.substr(amountOfNumbers * -1);

export const roundToTens = (number: number): number =>
  Math.round(number / 10) * 10;

export const roundToThousands = (number: number): number =>
  Math.round(number / 1000) * 1000;
