import React from 'react';

import { Button } from '../../components/Button/button';
import { Container } from '../../components/container/container';
import { Title } from '../../components/Title/title';
import { EmitableActionsType } from '../../Context/socketContext.types';

interface IShowFailedMessagesProps {
  isSocketActive: boolean;
  isSocketReconnecting: boolean;
  reconnectSocket(): void;
  failedMessages: {
    [key in EmitableActionsType['type']]: {
      value: EmitableActionsType;
      status: 'unverified' | 'received';
      sendTime: number;
    }[];
  };
  resendMessage(message: EmitableActionsType): void;
  resendAllMessages(): void;
  socketStateMessage: string;
}

export const ShowFailedMessages = ({
  isSocketActive,
  isSocketReconnecting,
  reconnectSocket,
  failedMessages,
  resendMessage,
  resendAllMessages,
  socketStateMessage,
}: IShowFailedMessagesProps): JSX.Element => (
  <Container className="mt-8 mb-16">
    <Title>Yhteyden tila - {socketStateMessage}</Title>
    <Button
      onClick={reconnectSocket}
      isDisabled={isSocketActive || isSocketReconnecting}
    >
      Yhdistä uudelleen
    </Button>
    <h2 className="text-3xl">Epäonnistuneet viestit</h2>
    <Button
      isDisabled={
        !isSocketActive || Object.entries(failedMessages).length === 0
      }
      onClick={resendAllMessages}
    >
      Lähetä uudelleen kaikki
    </Button>
    {Object.entries(failedMessages).map(([type, actions]) => (
      <>
        <h3 className="text-xl">{type}</h3>
        <ul style={{ flexDirection: 'column' }}>
          {actions.map(({ value: action, status, sendTime }) => (
            <li>
              {JSON.stringify({ ...action, status, sendTime })}
              <Button
                onClick={() => resendMessage(action)}
                isDisabled={!isSocketActive}
              >
                Lähetä uudelleen
              </Button>
            </li>
          ))}
        </ul>
      </>
    ))}
  </Container>
);
