/* eslint-disable react/button-has-type */
import React from 'react';

interface IButtonPlainProps {
  children: React.ReactNode;
  onClick(): void;
  className: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  testId?: string;
  isDisabled?: boolean;
  onTouchStart?(): void;
  onTouchEnd?(): void;
  onMouseDown?(): void;
  onMouseUp?(): void;
  onMouseLeave?(): void;
}

export const ButtonPlain = ({
  children,
  onClick,
  className,
  type = 'button',
  testId,
  isDisabled,
  onTouchStart,
  onTouchEnd,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
}: IButtonPlainProps): JSX.Element => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${className}`}
      data-test-id={testId}
      disabled={isDisabled}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};
