import {
  ICompetitorApiResponse,
  IRaceInfoApiResponse,
  IRaceInfoApiResponseWrapper,
  ITeamInfoApiResponse,
} from '../types/apiResponse';

import { getList } from './dummyDataGenerator';

const RACE_INFO_ENDPOINT = process.env.REACT_APP_RACE_INFO;
const RACE_INFO_ENDPOINT_TIMING = process.env.REACT_APP_RACE_INFO_TIMING;
const RACE_LIST_ENDPOINT = process.env.REACT_APP_RACE_LIST || '';
const TEAM_INFO = process.env.REACT_APP_TEAM_INFO || '';
const COMPETITOR_LIST_ENDPOINT = process.env.REACT_APP_COMPETITOR_LIST || '';
const START_LIST_ENDPOINT = process.env.REACT_APP_START_LIST || '';
const START_LIST_ENDPOINT_TIMING =
  process.env.REACT_APP_START_LIST_TIMING || '';

const TIME_SYNC_API = process.env.REACT_APP_TIME_SYNC_API || '';

export const fetchAllRaceInfo = async (
  teamId: number
): Promise<IRaceInfoWrapper[]> => {
  const response = await fetch(RACE_LIST_ENDPOINT + teamId);
  const raceInfo = (await response.json()) as IRaceInfoApiResponseWrapper[];

  return raceInfo || [];
};

export const fetchRaceInfo = async (
  raceId: string | number
): Promise<IRaceInfo> => {
  const targetEndpoint =
    (typeof raceId === 'number'
      ? RACE_INFO_ENDPOINT
      : RACE_INFO_ENDPOINT_TIMING) || '';
  const response = await fetch(targetEndpoint + raceId);
  const raceInfo = (await response.json()) as IRaceInfoApiResponse;

  return raceInfo;
};

export const fetchRaceName = async (
  raceId: string | number
): Promise<string> => {
  const raceInfo = await fetchRaceInfo(raceId);

  return raceInfo.name;
};

export const fetchTeamInfo = async (teamId: number): Promise<ITeamInfo> => {
  const teamInfoResponse = await fetch(TEAM_INFO + teamId);
  const [teamInfo] = (await teamInfoResponse.json()) as ITeamInfoApiResponse[];

  return teamInfo;
};

export const fetchCompetitorList = async (
  raceId: number | 'test'
): Promise<ICompetitorListCompetitor[]> => {
  let newCompetitorList;
  if (raceId !== 'test') {
    const response = await fetch(COMPETITOR_LIST_ENDPOINT + raceId);
    newCompetitorList = (await response.json()) as ICompetitorApiResponse[];
  } else {
    newCompetitorList = getList();
  }

  return newCompetitorList.map((competitor, index) => ({
    ...competitor,
    generatedListId: index,
  }));
};

export const fetchStartList = async (
  raceId: number | string | 'test'
): Promise<{
  competitors: ICompetitor[];
  categories: ICategory[];
  emptyFinishTimes: number[];
}> => {
  const targetEndpoint =
    raceId.toString().length < 30
      ? START_LIST_ENDPOINT
      : START_LIST_ENDPOINT_TIMING;

  let startList;
  if (raceId !== 'test') {
    const startListResponse = await fetch(targetEndpoint + raceId);
    startList = (await startListResponse.json()) as ICompetitorApiResponse[];
  } else {
    startList = getList();
  }

  const categories = startList?.reduce<ICategory[]>(
    (prev, cur) =>
      prev.some((category) => category.key === cur.category)
        ? prev
        : prev.concat({
            key: cur.category,
            name: cur.categoryname || cur.category,
          }),
    []
  );

  const competitors = startList
    ?.filter(({ bib, name }) => bib && name)
    .map<ICompetitor>(({ categoryname, bib, ...competitor }) => ({
      ...competitor,
      bib: parseInt(bib, 10),
    }))
    .sort((a, b) => a.bib - b.bib)
    .sort((a, b) => a.starttime - b.starttime);

  const emptyFinishTimes = startList
    ?.filter(({ bib }) => !bib)
    .map(({ finishtime }) => finishtime || -1)
    .filter((finishTime) => finishTime >= 0);

  return { competitors, categories, emptyFinishTimes };
};

export const fetchResultList = async (
  raceId: number | string | 'test'
): Promise<{
  competitors: ICompetitorWithTime[];
  categories: ICategory[];
  emptyFinishTimes: number[];
}> => {
  const { competitors, categories, emptyFinishTimes } = await fetchStartList(
    raceId
  );

  const currentTimestamp = Date.now();
  const competitorsWithTime = competitors
    .map(({ status, starttime, finishtime, ...competitor }) => ({
      ...competitor,
      starttime,
      finishtime,
      status,
      time: finishtime && !status ? finishtime - starttime : null,
    }))
    .sort(
      (a, b) => (a.time || currentTimestamp) - (b.time || currentTimestamp)
    );
  return { competitors: competitorsWithTime, categories, emptyFinishTimes };
};

export const getTimeSyncInfo = async (): Promise<{
  timeDiff: number;
  ping: number;
}> => {
  console.log(TIME_SYNC_API);
  const timeBefore = Date.now();
  const { time: serverTime } = await (await fetch(TIME_SYNC_API)).json();
  const networkLatency = Date.now() - timeBefore;
  const ping = networkLatency / 2;
  const timeDiff = serverTime - timeBefore + ping;

  return { timeDiff, ping };
};
