import React from 'react';

import { Button } from '../../components/Button/button';
import { Icon, IconType } from '../../components/Icon/icon';

interface IExternalLinkProps {
  link: string;
  mimeType: string;
  className?: string;
  children: React.ReactNode;
}

const mimeTypeToIcon: { [key in string]: IconType } = {
  'application/pdf': 'pdf',
  'text/html': 'code',
  'text/csv': 'excel',
};

export const ExternalLink = ({
  link,
  mimeType,
  className = '',
  children,
}: IExternalLinkProps): JSX.Element => {
  const iconType = mimeType ? mimeTypeToIcon[mimeType] : 'code';
  const linkType = iconType === 'code' ? 'external' : 'download';

  return (
    <Button accentColor="plain" link={link} className={className}>
      <span className="flex items-center">
        <Icon type={iconType} className="inline-block mr-1" /> {children}
      </span>
      <Icon type={linkType} />
    </Button>
  );
};
