import React from 'react';

import { Button } from '../../components/Button/button';
import { Container } from '../../components/container/container';
import { Loader } from '../../components/loader/loader';
import { Title } from '../../components/Title/title';
import { selectToClibboard } from '../../services/utils';

interface IExportDataProps<DataType> {
  children?: React.ReactNode;
  fileNamePrefix?: string;
  data: {
    raceId: null | number;
    data: DataType;
  };
}

const getTimeString = () => {
  const addLeadingZero = (number: number) => `0${number}`.substr(-2);
  const now = new Date();

  const year = now.getFullYear();
  const month = addLeadingZero(now.getMonth() + 1);
  const day = addLeadingZero(now.getDate());

  const hours = addLeadingZero(now.getHours());
  const minutes = addLeadingZero(now.getMinutes());
  const seconds = addLeadingZero(now.getSeconds());

  return `${year}${month}${day}-${hours}${minutes}${seconds}`;
};

export const ExportData = <DataType extends unknown>({
  children = 'Vie tiedot',
  data,
  fileNamePrefix = 'data',
}: IExportDataProps<DataType>): JSX.Element => {
  if (!data) {
    return <Loader />;
  }

  const dataStr = JSON.stringify(data);
  const dataFile = new Blob([dataStr], { type: 'text/plain' });
  return (
    <Container className="mt-8 mb-16">
      <Title>{children}</Title>
      <textarea
        id="data-block"
        className="w-full h-[calc(100vh-210px)] sm:h-[calc(100vh-185px)]"
        value={dataStr}
        readOnly
      />
      <div className="flex justify-start gap-4">
        <Button
          onClick={() =>
            selectToClibboard(
              document.getElementById(
                'data-block'
              ) as HTMLTextAreaElement | null
            )
          }
        >
          Kopio tiedot leikepöydälle
        </Button>
        <Button
          download={`${fileNamePrefix}_${getTimeString()}.json`}
          link={window.URL.createObjectURL(dataFile)}
        >
          Lataa tiedot
        </Button>
      </div>
    </Container>
  );
};
