import { injectModalConfirm } from '../../components/modal/confirm/modal.confirm';
import { IRootColumn, Table } from '../../components/Table/table';
import {
  CATEGORY_CLASSES,
  TIME_CLASSES,
} from '../../components/Table/table.constants';

export interface ChangedField {
  fieldName:
    | 'bib'
    | 'name'
    | 'team'
    | 'startTime'
    | 'finishTime'
    | 'category'
    | 'status';
  oldValue: string;
  newValue: string;
}

interface IConfirmModalBodyProps {
  changedFields: ChangedField[];
  bib: number;
  name: string;
}

const columns: IRootColumn[] = [
  {
    key: 'fieldName',
    title: 'Kenttä',
    widthClassName: CATEGORY_CLASSES,
  },
  {
    key: 'oldValue',
    title: 'Vanha',
    widthClassName: TIME_CLASSES,
  },
  {
    key: 'newValue',
    title: 'Uusi',
    widthClassName: TIME_CLASSES,
  },
];

const ConfirmModalBody = ({
  changedFields,
  bib,
  name,
}: IConfirmModalBodyProps): JSX.Element => (
  <div>
    <h2 className="text-xl my-4">
      Kilpailija nro {bib} {name}
    </h2>
    <Table
      columns={columns}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data={changedFields as any}
      indexColumnName={'fieldName'}
    />
  </div>
);

export const confirmUpdateModal = (
  changedFields: ChangedField[],
  bib: number,
  name: string,
  onConfirm: () => void
): void => {
  return injectModalConfirm({
    children: (
      <ConfirmModalBody changedFields={changedFields} bib={bib} name={name} />
    ),
    label: 'Haluatko varmasti päivittää kilpailijan tiedot',
    onConfirm,
    onCancel: () => console.log('cancelled'),
    submitButtonLabel: 'Päivitä',
    modalOpenButtonLabel: 'Päivitä',
  });
};
