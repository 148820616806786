import React from 'react';

import { ButtonExternal } from './button.external';
import { ButtonInternal } from './button.internal';
import { ButtonPlain } from './button.plain';

type AccentColor = 'red' | 'green' | 'brand' | 'plain';
export interface IButtonProps {
  accentColor?: AccentColor;
  children: React.ReactNode;
  className?: string;
  link?: string;
  onClick?(): void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  testId?: string;
  isDisabled?: boolean;
  isVisiallyDisabled?: boolean;
  onTouchStart?(): void;
  onTouchEnd?(): void;
  onMouseDown?(): void;
  onMouseUp?(): void;
  onMouseLeave?(): void;
  download?: string;
  width?: 'compact' | 'mobile-full-width';
}

export const isExternalLink = (link: string): boolean =>
  link.substr(0, 8) === 'https://' ||
  link.substr(0, 7) === 'http://' ||
  link.substr(0, 2) === '//' ||
  link.substr(0, 5) === 'blob:' ||
  link.substr(0, 5) === '/api/' ||
  link.substr(0, 6) === '/auth/';

const getButtonColorClasses = (color: AccentColor): string => {
  switch (color) {
    case 'brand':
      return 'bg-brand-color hover:bg-opacity-90 active:bg-opacity-90 focus:ring-blue-500';
    case 'green':
      return 'bg-green-500 hover:bg-green-400 active:bg-green-600 focus:ring-green-400';
    case 'red':
      return 'bg-red-600 hover:bg-red-500 active:bg-red-700 focus:ring-red-500';
    default:
      return '';
  }
};

export const Button = ({
  accentColor = 'brand',
  children,
  className = '',
  link,
  onClick = () => {},
  type = 'button',
  testId,
  isDisabled,
  isVisiallyDisabled,
  onTouchStart,
  onTouchEnd,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  download,
  width = 'mobile-full-width',
}: IButtonProps): JSX.Element => {
  const elementClasses = [
    `inline-flex justify-center rounded-md items-center py-3 px-6 border font-medium text-base text-white`,
    `focus-within:ring-2 focus:ring-inset focus:ring-blue-500 focus:outline-none transition ease-in-out duration-150`,
    `disabled:opacity-50 disabled:cursor-not-allowed`,
    isVisiallyDisabled ? 'opacity-50 cursor-not-allowed' : '',
    width === 'mobile-full-width' ? 'w-full sm:w-auto ' : 'w-auto',
    className,
  ];

  if (accentColor === 'plain') {
    elementClasses.push(
      `border-gray-300 bg-white text-gray-700 shadow-sm hover:text-gray-500 focus:ring-blue-500`
    );
  } else {
    elementClasses.push(
      `border-transparent hover:text-white ${getButtonColorClasses(
        accentColor
      )}`
    );
  }

  if (typeof link === 'string' && link.length > 0 && !isDisabled) {
    if (isExternalLink(link)) {
      return (
        <ButtonExternal
          link={link}
          className={elementClasses.join(' ')}
          onClick={onClick}
          testId={testId}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
          download={download}
        >
          {children}
        </ButtonExternal>
      );
    }

    return (
      <ButtonInternal
        link={link}
        className={elementClasses.join(' ')}
        onClick={onClick}
        testId={testId}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </ButtonInternal>
    );
  }

  return (
    <ButtonPlain
      type={type}
      onClick={onClick}
      className={elementClasses.join(' ')}
      testId={testId}
      isDisabled={isDisabled}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </ButtonPlain>
  );
};
