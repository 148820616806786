import React from 'react';

import { useSetTimeout } from '../../hooks/useSetTimeout';

import { Button, IButtonProps } from './button';

interface ILongPressButtonProps extends IButtonProps {
  pressDelay?: number;
  onLongPress(): void;
}

export const ButtonLongPress = ({
  pressDelay = 1500,
  onLongPress,
  ...props
}: ILongPressButtonProps): JSX.Element => {
  const [startTimeout, removeTimeout] = useSetTimeout();

  const handleButtonPress = () => {
    startTimeout(() => onLongPress(), pressDelay);
  };

  const handleButtonRelease = () => {
    removeTimeout();
  };

  return (
    <Button
      {...props}
      onTouchStart={handleButtonPress}
      onTouchEnd={handleButtonRelease}
      onMouseDown={handleButtonPress}
      onMouseUp={handleButtonRelease}
      onMouseLeave={handleButtonRelease}
    />
  );
};
