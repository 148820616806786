import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchRaceInfo } from '../services/apiService';

interface IRaceInfoProviderProps {
  children: React.ReactNode;
}

interface IRaceInfoUpdaterProps {
  children: React.ReactNode;
}

export interface RaceInfoContextRace extends IRaceInfo {
  raceToken?: string;
}

type RaceInfoContextRaceInfo = RaceInfoContextRace | null;

type RaceInfoContextType = [RaceInfoContextRaceInfo, (raceId: string) => void];

const initialState: RaceInfoContextRaceInfo = null;

const testRaceInfoState: RaceInfoContextRaceInfo = {
  id: 'test' as unknown as number,
  name: 'Test Race',
  description: 'Test race description',
  date: '2022-01-01 00:00',
  place: 'Test race place',
  msg: 'Test race message',
  links: [
    { name: 'Sample link 1', url: '#link-target', mimetype: '' },
    { name: 'Sample link 2', url: '#link-target', mimetype: 'application/pdf' },
    { name: 'Sample link 3', url: '#link-target', mimetype: 'text/html' },
    { name: 'Sample link 4', url: '#link-target', mimetype: 'text/csv' },
  ],
  competitorList: true,
  startList: true,
  resultList: true,
  live: true,
};

export const RaceInfoContext = createContext<RaceInfoContextType>([
  initialState,
  () => {},
]);

export const useRaceInfoContext = (): RaceInfoContextType =>
  useContext(RaceInfoContext);

export const RaceInfoProvider = ({
  children,
}: IRaceInfoProviderProps): JSX.Element => {
  const [raceInfo, setRaceInfo] = useState<RaceInfoContextRace | null>(
    initialState
  );
  const [raceId, setRaceId] = useState<string | null>(null);

  useEffect(() => {
    if (!raceId) {
      setRaceInfo(initialState);
      return;
    }
    const updateRaceInfo = async (newRaceId: number | string) => {
      const raceInfoResponse = await fetchRaceInfo(newRaceId);
      if (typeof newRaceId === 'string') {
        setRaceInfo({ ...raceInfoResponse, raceToken: newRaceId });
      } else {
        setRaceInfo({ ...raceInfoResponse });
      }
    };

    if (raceId === 'test') {
      setRaceInfo(testRaceInfoState);
      return;
    }

    const parsedRaceId = parseInt(raceId, 10) || raceId;
    updateRaceInfo(
      parsedRaceId.toString().length === raceId.length ? parsedRaceId : raceId
    );
  }, [raceId]);

  return (
    <RaceInfoContext.Provider value={[raceInfo, setRaceId]}>
      {children}
    </RaceInfoContext.Provider>
  );
};

export const RaceInfoUpdater = ({
  children,
}: IRaceInfoUpdaterProps): React.ReactNode => {
  const { raceId } = useParams<{ raceId: string }>();
  const [, setRaceId] = useRaceInfoContext();
  setRaceId(raceId);

  return children;
};
