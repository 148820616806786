import React, { ChangeEvent } from 'react';

interface IInputProps {
  children: React.ReactNode;
  help?: string;
  id: string;
  isRequired?: boolean;
  type?: 'text' | 'number' | 'datetime-local' | 'time' | 'date';
  value?: string | number;
  forcedValue?: string | number;
  min?: number;
  max?: number;
  step?: number;
  ref?: React.MutableRefObject<null>;
  placeholder?: string;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  isDisabled?: boolean;
  className?: string;
}

export const Input = ({
  children,
  help = '',
  id,
  isRequired = false,
  type = 'text',
  min,
  max,
  step,
  value = '',
  forcedValue,
  placeholder,
  ref,
  onChange,
  isDisabled,
  className = '',
}: IInputProps): JSX.Element => {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className="block text-xs font-semibold uppercase leading-5 tracking-wider text-gray-500"
      >
        {children}
      </label>
      <div className="mt-1 relative rounded-md">
        <input
          id={id}
          type={type}
          min={min}
          max={max}
          step={step}
          className={`appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-financer focus:border-blue-financer text-gray-900 disabled:opacity-70 disabled:cursor-not-allowed`}
          aria-describedby={help && `${id}-kuvaus`}
          defaultValue={value}
          value={forcedValue}
          required={isRequired}
          ref={ref}
          onChange={onChange}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      </div>
      {help && (
        <p className="mt-2 text-sm text-gray-500" id={`${id}-kuvaus`}>
          {help}
        </p>
      )}
    </div>
  );
};
