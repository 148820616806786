/* eslint-disable react/no-danger, react/prop-types */
import React from 'react';

interface IRaceListRaceInfoProps {
  date?: string;
  description?: string;
  msg?: string;
}

export const RaceListRaceInfo = ({
  date,
  description,
  msg,
}: IRaceListRaceInfoProps): JSX.Element => (
  <>
    {date && <p className="">{date}</p>}
    {msg && (
      <p
        className=""
        dangerouslySetInnerHTML={{ __html: msg.replace(/font-size.*;/i, '') }}
      />
    )}
    {description && (
      <p
        className=""
        dangerouslySetInnerHTML={{
          __html: description.replace(/font-size.*;/i, ''),
        }}
      />
    )}
  </>
);
