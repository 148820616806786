import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { EmptyFinishTimesContainer } from './containers/Timing/emptyFinishTime.container';
import { ExportFailedMessagesContainer } from './containers/Timing/exportFailedMessages.container';
import { FinishContainer } from './containers/Timing/finish.container';
import { RaceInfoContainer } from './containers/Timing/raceInfo.container';
import { ShowFailedMessagesContainer } from './containers/Timing/showFailedMessages.container';
import { SocketStatusBarContainer } from './containers/Timing/SocketStatusBar.container';
import { StartContainer } from './containers/Timing/Start.container';
import { TimingContainer } from './containers/Timing/Timing.Container';
import { TimingExportDataContainer } from './containers/Timing/Timing.exportData.container';
import { TimingNavigation } from './containers/Timing/Timing.navigation';
import { TimingResultsContainer } from './containers/Timing/Timing.Results.container';
import { TimingStartWatchContainer } from './containers/Timing/Timing.StartWatch.container';
import { TimingContextWrapper } from './Context/TimingContext.wrapper';
import { useTeamIdQueryString } from './hooks/useTeamIdQueryString';

export const TimingApp = () => {
  const queryString = useTeamIdQueryString();

  return (
    <TimingContextWrapper>
      <TimingNavigation />
      <SocketStatusBarContainer />
      <TimingContainer>
        <Switch>
          <Redirect
            exact
            from="/ajanotto/:race_token"
            to={`/ajanotto/:race_token/tiedot${queryString}`}
          />
          <Route
            exact
            path="/ajanotto/:race_token/lahto"
            component={StartContainer}
          />
          <Route
            exact
            path="/ajanotto/:race_token/lahtokello"
            component={TimingStartWatchContainer}
          />

          <Route
            exact
            path="/ajanotto/:race_token/maali"
            component={FinishContainer}
          />
          <Route
            exact
            path="/ajanotto/:race_token/maali/maaliajat"
            component={EmptyFinishTimesContainer}
          />
          <Route
            exact
            path="/ajanotto/:race_token/tulokset"
            component={TimingResultsContainer}
          />
          <Route
            exact
            path="/ajanotto/:race_token/tiedot"
            component={RaceInfoContainer}
          />
          <Route
            exact
            path="/ajanotto/:race_token/tiedot/data"
            component={TimingExportDataContainer}
          />
          <Route
            exact
            path="/ajanotto/:race_token/tiedot/virhe-data"
            component={ExportFailedMessagesContainer}
          />
          <Route
            exact
            path="/ajanotto/:race_token/tiedot/virheet"
            component={ShowFailedMessagesContainer}
          />
        </Switch>
      </TimingContainer>
    </TimingContextWrapper>
  );
};
