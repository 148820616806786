import React from 'react';

import { RaceListBox } from './raceList.box';

interface IRaceListGroupProps {
  title: string;
  races: IRaceInfo[];
}

export const RaceListGroup = ({
  title,
  races,
}: IRaceListGroupProps): JSX.Element => (
  <div className="my-6">
    {title && <h2 className="mb-4 text-2xl sm:text-4xl">{title}</h2>}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {races.map(
        ({
          id,
          name,
          date,
          links,
          description,
          msg,
          live,
          competitorList,
          startList,
          resultList,
        }) => (
          <RaceListBox
            key={id}
            id={id}
            name={name}
            date={date}
            links={links}
            description={description}
            msg={msg}
            live={live}
            competitorList={competitorList}
            startList={startList}
            resultList={resultList}
          />
        )
      )}
    </div>
  </div>
);
