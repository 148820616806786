import React, { useState, useEffect } from 'react';

import { useRaceInfoContext } from '../Context/raceInfoContext';
import { StartList } from '../pages/StartList/startList';
import { fetchStartList } from '../services/apiService';

export const StartListContainer = (): JSX.Element => {
  const [raceInfo] = useRaceInfoContext();
  const [startList, setStartList] = useState<ICompetitor[] | null>(null);
  const [categories, setCategories] = useState<ICategory[] | null>(null);

  useEffect(() => {
    if (!raceInfo?.id) return;

    const getStartList = async () => {
      const { competitors, categories: newCategories } = await fetchStartList(
        raceInfo.id
      );
      setStartList(competitors || []);
      setCategories(newCategories || []);
    };

    getStartList();
  }, [raceInfo?.id]);

  return (
    <StartList
      startList={startList}
      categories={categories}
      raceName={raceInfo?.name || ''}
    />
  );
};
