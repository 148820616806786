import React from 'react';
import { Link } from 'react-router-dom';

import { useTeamIdQueryString } from '../../hooks/useTeamIdQueryString';

interface IButtonInternalProps {
  children: React.ReactNode;
  className: string;
  link: string;
  onClick?(): void;
  testId?: string;
  onTouchStart?(): void;
  onTouchEnd?(): void;
  onMouseDown?(): void;
  onMouseUp?(): void;
  onMouseLeave?(): void;
}

export const ButtonInternal = ({
  children,
  className,
  link,
  onClick,
  testId,
  onTouchStart,
  onTouchEnd,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
}: IButtonInternalProps): JSX.Element => {
  const queryString = useTeamIdQueryString();

  return (
    <Link
      to={link + queryString}
      className={className}
      onClick={onClick}
      data-test-id={testId}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Link>
  );
};
