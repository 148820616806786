import React from 'react';

interface IButtonExternalProps {
  children: React.ReactNode;
  className: string;
  link: string;
  onClick?(): void;
  testId?: string;
  onTouchStart?(): void;
  onTouchEnd?(): void;
  onMouseDown?(): void;
  onMouseUp?(): void;
  onMouseLeave?(): void;
  download?: string;
}

export const ButtonExternal = ({
  children,
  className,
  link,
  onClick,
  testId,
  onTouchStart,
  onTouchEnd,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  download,
}: IButtonExternalProps): JSX.Element => {
  return (
    <a
      href={link}
      className={className}
      onClick={onClick}
      data-test-id={testId}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      download={download}
    >
      {children}
    </a>
  );
};
