import { useEffect, useState } from 'react';

import {
  useCompetitorValue,
  SET_STATUS,
} from '../../Context/competitorContext';
import { useEmitAndDispatchCompetitors } from '../../Context/socketContext.helper';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useRaceUnixtime } from '../../hooks/useRaceTime';
import { useSetTimeout } from '../../hooks/useSetTimeout';
import { Start } from '../../pages/Start/Start';
import { useTimeToStart } from '../../pages/Start/useTimeToStart';

const SHOW_COMPETITOR_AFTER_START_MS = 5000;
const COMPETITOR_COUNT = 10;

export const StartContainer = (): JSX.Element => {
  const getTimeToStart = useTimeToStart();
  const getRaceTime = useRaceUnixtime();
  const [startList, setStartList] = useState<ICompetitor[] | null>(null);

  const [forceUpdate, forceUpdatedState] = useForceUpdate();
  const [startTimeout] = useSetTimeout();

  const [{ competitors }] = useCompetitorValue();
  const dispatchCompetitor = useEmitAndDispatchCompetitors();

  useEffect(() => {
    if (!competitors) {
      return;
    }
    const newStartList =
      competitors
        ?.filter(
          (competitor) =>
            getRaceTime() <
            competitor.starttime + SHOW_COMPETITOR_AFTER_START_MS
        )
        .slice(0, COMPETITOR_COUNT) || [];

    if (newStartList.length > 0) {
      const firstStartTime = newStartList[0].starttime;
      const timeToNextStart = getTimeToStart(firstStartTime);

      const setHighlightAfterStart = () => {
        setStartList(
          (currentStartList) =>
            currentStartList?.map(({ starttime, ...competitor }) => ({
              ...competitor,
              starttime,
              highlight: starttime === firstStartTime,
            })) || []
        );
      };

      startTimeout(setHighlightAfterStart, timeToNextStart);
      startTimeout(
        forceUpdate,
        timeToNextStart + SHOW_COMPETITOR_AFTER_START_MS
      );
    }
    setStartList(newStartList);
  }, [
    competitors,
    forceUpdate,
    startTimeout,
    forceUpdatedState,
    getRaceTime,
    getTimeToStart,
  ]);

  const toggleDns = (bib: number) => {
    const competitor = competitors?.find(
      (currentCompetitor) => currentCompetitor.bib === bib
    );
    if (!competitor) {
      console.log('No competitor with ' + bib + 'bib found');
      return;
    }

    const status = competitor.status.toLocaleUpperCase() === 'DNS' ? '' : 'DNS';

    dispatchCompetitor({ type: SET_STATUS, payload: { bib, status } });
  };

  return <Start startList={startList} toggleDns={toggleDns} />;
};
