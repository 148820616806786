const audioCtx = new AudioContext();

const playBeep = (duration: number, volume = 1) => {
  const oscillator = audioCtx.createOscillator();
  const gainNode = audioCtx.createGain();

  oscillator.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  gainNode.gain.value = volume;
  oscillator.frequency.value = 1000;
  oscillator.type = 'triangle';

  oscillator.start();

  setTimeout(function () {
    oscillator.stop();
  }, duration);
};

export const playShortBeep = (): void => {
  playBeep(250, 0.8);
};

export const playLongBeep = (): void => {
  playBeep(500);
};
