import React from 'react';

import { Button } from '../../components/Button/button';
import { RaceClock } from '../../components/Clock/RaceClock';
import { Container } from '../../components/container/container';
import { IRootColumn, Table } from '../../components/Table/table';
import {
  CATEGORY_CLASSES,
  NAME_CLASSES,
  NUMBER_CLASSES,
  TIME_CLASSES,
} from '../../components/Table/table.constants';
import { Title } from '../../components/Title/title';
import { msToStr } from '../../services/time';

import { TimeToStart } from './Start.timeToStart';

interface IStartListProps {
  startList: ICompetitor[] | null;
  toggleDns?: (bib: number) => void;
}

interface IStartCompetitor extends ICompetitor {
  timeToStart: React.ReactNode;
  toggleDns: React.ReactNode;
  passive?: boolean;
}

const getColumns = (hasToggledns: boolean): IRootColumn[] => [
  {
    key: 'bib',
    title: 'Nro',
    widthClassName: NUMBER_CLASSES,
    firstRowClassName: 'text-2xl',
  },
  {
    key: 'name',
    title: 'Nimi',
    widthClassName: NAME_CLASSES,
    firstRowClassName: 'text-2xl truncate',
    accent: { key: 'team', title: 'Seura' },
  },
  {
    key: 'category',
    title: 'Sarja',
    firstRowClassName: 'text-2xl',
    widthClassName: CATEGORY_CLASSES,
  },
  {
    key: 'starttime',
    title: 'Lähtöaika',
    widthClassName: TIME_CLASSES,
    firstRowClassName: 'text-2xl',
    formatter: (startTime) =>
      msToStr(
        typeof startTime === 'number' ? startTime : parseInt(startTime, 10),
        'HH.MM.SS'
      ),
  },
  {
    key: 'timeToStart',
    title: 'Aikaa lähtöön',
    firstRowClassName: 'text-xl',
    widthClassName: TIME_CLASSES,
  },
  ...(hasToggledns
    ? [
        {
          key: 'toggleDns',
          title: 'DNS',
          widthClassName: TIME_CLASSES,
        },
      ]
    : []),
];

export const Start = ({
  startList,
  toggleDns,
}: IStartListProps): JSX.Element => {
  const computedStartList = !startList
    ? startList
    : startList?.map<IStartCompetitor>(
        ({ starttime, status, bib, ...competitors }) => ({
          ...competitors,
          passive: Boolean(status),
          starttime,
          status,
          bib,
          timeToStart: <TimeToStart startTime={starttime} />,
          toggleDns: toggleDns ? (
            <Button
              className="relative z-10"
              accentColor={status === '' ? 'green' : 'red'}
              onClick={() => toggleDns(bib)}
            >
              {status === '' ? 'Osal' : 'Pois'}
            </Button>
          ) : undefined,
        })
      );

  return (
    <Container className="mt-6 mb-16">
      <div className="flex justify-between">
        <Title>Lähtölista</Title>
        <RaceClock />
      </div>
      <Table
        columns={getColumns(!!toggleDns)}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data={computedStartList as any}
        indexColumnName="bib"
        noRowsMessage="Ei tulevia lähtijöitä"
      />
    </Container>
  );
};
