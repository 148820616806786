import React, { useEffect } from 'react';

import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { msToStr } from '../../services/time';

import { useTimeToStart } from './useTimeToStart';

interface IStartListTimeToStartProps {
  startTime: number;
}

export const TimeToStart = ({
  startTime,
}: IStartListTimeToStartProps): JSX.Element => {
  const getTimeToStart = useTimeToStart();
  const [startRefresh, stopRefresh] = useAutoRefresh(100);

  const timeToStart = getTimeToStart(startTime);

  useEffect(() => {
    startRefresh();

    return () => stopRefresh();
  }, [startRefresh, stopRefresh]);

  const getDiffStr = (ms: number) => msToStr(ms, 'MM.SS.f');
  const timeToStartStr = `-${getDiffStr(timeToStart < 0 ? 0 : timeToStart)}`;

  return <span>{timeToStartStr}</span>;
};
