import React from 'react';

import { CategoryNavigationItem } from './categoryNavigation.item';

interface ICategoryNavigationProps {
  categoryList: ICategory[];
  className?: string;
}

export const CategoryNavigation = ({
  categoryList,
  className = '',
}: ICategoryNavigationProps): JSX.Element => {
  return (
    <ul
      className={`fixed top-0 left-0 mx-auto justify-center flex flex-row overflow-x-auto w-full whitespace-nowrap ${className}`}
    >
      {categoryList.map(({ key, highlight }) => (
        <CategoryNavigationItem key={key} name={key} highlight={highlight} />
      ))}
    </ul>
  );
};
