import React from 'react';

import { Button } from '../../components/Button/button';
import { Input } from '../../components/input/input';
import { msToStr } from '../../services/time';

interface IEmptyFinishTimeItemProps {
  finishTime: number;
  removeEmptyFinishTime(): void;
  handleSetFinishTimeSubmit(event: React.FormEvent<EventTarget>): void;
  bib?: number;
}

export const EmptyFinishTimeItem = ({
  finishTime,
  removeEmptyFinishTime,
  handleSetFinishTimeSubmit,
  bib,
}: IEmptyFinishTimeItemProps): JSX.Element => (
  <form
    onSubmit={handleSetFinishTimeSubmit}
    className="flex justify-between items-center gap-2"
  >
    <p className="empty-finish-time__time">
      {msToStr(finishTime, 'H.MM.SS.f')}
    </p>
    <Input
      placeholder="Numero"
      type="number"
      id="bib"
      value={bib}
      isRequired
      children={undefined}
    />
    <Button
      className="px-4"
      type="submit"
      accentColor="green"
      isDisabled={Boolean(bib)}
      width="compact"
    >
      Aseta
    </Button>
    <Button
      onClick={removeEmptyFinishTime}
      className="px-4"
      accentColor="red"
      isDisabled={Boolean(bib)}
      width="compact"
    >
      X
    </Button>
  </form>
);
