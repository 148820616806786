import { useEffect, useState } from 'react';

import {
  useCompetitorValue,
  SET_FINISH_TIME,
} from '../../Context/competitorContext';
import { ADD_EMPTY_FINISH_TIME } from '../../Context/emptyFinishTimeContext';
import { useSettings } from '../../Context/settings/settingsContext';
import {
  useEmitAndDispatchCompetitors,
  useEmitAndDispatchEmptyFinishTime,
} from '../../Context/socketContext.helper';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useRaceUnixtime } from '../../hooks/useRaceTime';
import { useSetTimeout } from '../../hooks/useSetTimeout';
import { Finish } from '../../pages/Finish/finish';
import { useTimeToStart } from '../../pages/Start/useTimeToStart';

export const FinishContainer = (): JSX.Element => {
  const getTimeToStart = useTimeToStart();
  const getRaceTime = useRaceUnixtime();
  const [onTrack, setOnTrack] = useState<ICompetitorWithTime[] | null>(null);
  const [forceUpdate, forceUpdatedState] = useForceUpdate();
  const [startTimeout] = useSetTimeout();

  const dispatchCompetitors = useEmitAndDispatchCompetitors();
  const dispatchEmptyFinishTime = useEmitAndDispatchEmptyFinishTime();
  const [{ raceToken }] = useSettings();
  const [{ competitors }] = useCompetitorValue();

  useEffect(() => {
    if (!competitors) {
      return;
    }

    const newOnTrack =
      competitors
        ?.filter((competitor) => competitor.starttime < getRaceTime())
        .sort((a, b) => a.bib - b.bib) || null;

    const nextStartTime = competitors
      .sort((a, b) => a.starttime - b.starttime)
      .find((competitor) => !newOnTrack.includes(competitor));
    if (nextStartTime !== undefined) {
      startTimeout(forceUpdate, getTimeToStart(nextStartTime.starttime));
    }

    setOnTrack(newOnTrack);
  }, [
    competitors,
    forceUpdate,
    startTimeout,
    forceUpdatedState,
    getRaceTime,
    getTimeToStart,
  ]);

  const setFinishTime = (bib: number) =>
    dispatchCompetitors({
      type: SET_FINISH_TIME,
      payload: { bib, finishTime: getRaceTime() },
    });

  const removeFinishTime = (bib: number) =>
    dispatchCompetitors({
      type: SET_FINISH_TIME,
      payload: { bib, finishTime: 0 },
    });

  const addEmptyFinishTime = () =>
    dispatchEmptyFinishTime({
      type: ADD_EMPTY_FINISH_TIME,
      payload: getRaceTime(),
    });
  return (
    <Finish
      onTrack={onTrack}
      setFinish={setFinishTime}
      removeFinish={removeFinishTime}
      addEmptyFinishTime={addEmptyFinishTime}
      raceId={raceToken || ''}
    />
  );
};
