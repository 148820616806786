import React, { useState, useEffect } from 'react';

import { useCompetitorValue } from '../../Context/competitorContext';
import { useEmptyFinishTimeValue } from '../../Context/emptyFinishTimeContext';
import { useSettings } from '../../Context/settings/settingsContext';
import { ExportData } from '../../pages/ExportData/exportData';

export const TimingExportDataContainer = (): JSX.Element => {
  const [{ competitors }] = useCompetitorValue();
  const [emptyFinishTimes] = useEmptyFinishTimeValue();
  const [{ raceId }] = useSettings();
  const [data, setData] = useState<Partial<ICompetitor>[] | null>(null);

  useEffect(() => {
    const formattedEmptyFinishTimes = emptyFinishTimes.map<
      Partial<ICompetitor>
    >((emptyFinishTime) => ({ finishtime: emptyFinishTime }));
    if (!competitors) {
      return;
    }

    const mergedData: Partial<ICompetitor>[] = [
      ...competitors,
      ...formattedEmptyFinishTimes,
    ];
    setData(mergedData);
  }, [competitors, emptyFinishTimes]);

  return (
    <ExportData
      data={{ raceId: raceId || null, data }}
      fileNamePrefix={`race-${raceId}_full-data`}
    />
  );
};
