import { useCallback, useEffect, useState } from 'react';

import { useForceUpdate } from './useForceUpdate';

export const useAutoRefresh = (
  interval: number
): [startRefreshing: () => void, stopRefreshing: () => void] => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [forceUpdate] = useForceUpdate();

  useEffect(() => {
    let updateTimer: NodeJS.Timeout;
    if (isRefreshing) updateTimer = setTimeout(forceUpdate, interval);
    return () => {
      clearTimeout(updateTimer);
    };
  });

  const startRefreshing = useCallback(() => {
    setIsRefreshing(true);
  }, []);
  const stopRefreshing = useCallback(() => {
    setIsRefreshing(false);
  }, []);

  return [startRefreshing, stopRefreshing];
};
