import React from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../components/Button/button';
import { Container } from '../../components/container/container';
import { Input } from '../../components/input/input';
import { Title } from '../../components/Title/title';
import { useTeamInfoContext } from '../../Context/teamInfoContext';
import { useTeamIdQueryString } from '../../hooks/useTeamIdQueryString';

export const RaceTokenForm = () => {
  const { logourl, name } = useTeamInfoContext();
  const queryString = useTeamIdQueryString();

  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    const { token } = e.target;
    history.push(`/ajanotto/${token.value}${queryString}`);
  };

  return (
    <Container className="my-4 sm:my-10">
      <Title className="flex items-center my-4 mb-8">
        <img src={logourl} alt="Logo" className="h-16 mr-2" />
        {name}
      </Title>

      <h2 className="text-3xl">Ajanotto</h2>

      <form onSubmit={handleSubmit} className="my-4">
        <Input id="token" required>
          Avain kilpailun ylläpitoon:
        </Input>
        <Button type="submit" className="my-4">
          Avaa ajanotto
        </Button>
      </form>

      <Button className="my-6" link="/">
        Takaisin
      </Button>
    </Container>
  );
};
