import React from 'react';

import { Button } from '../../components/Button/button';
import { Icon } from '../../components/Icon/icon';

interface IInternalLinkProps {
  link: string;
  className?: string;
  children: React.ReactNode;
}

export const InternalLink = ({
  link,
  className = '',
  children,
}: IInternalLinkProps): JSX.Element => (
  <Button link={link} className={className}>
    {children} <Icon type="external" />
  </Button>
);
