import React, { useEffect, useState } from 'react';

import { Button } from '../../components/Button/button';
import { Container } from '../../components/container/container';
import { Input } from '../../components/input/input';
import { Table } from '../../components/Table/table';
import {
  CATEGORY_CLASSES,
  NAME_CLASSES,
} from '../../components/Table/table.constants';
import { Title } from '../../components/Title/title';

const tableHeaders = [
  {
    key: 'name',
    title: 'Nimi',
    widthClassName: NAME_CLASSES,
    accent: {
      key: 'team',
      title: 'Seura',
    },
  },
  {
    key: 'category',
    title: 'Sarja',
    widthClassName: CATEGORY_CLASSES,
  },
];

interface ICompetitorListProps {
  competitorList: ICompetitorListCompetitor[] | null;
  raceName: string;
}

export const CompetitorList = ({
  competitorList,
  raceName,
}: ICompetitorListProps): JSX.Element => {
  const [filter, setFilter] = useState('');
  const [noRowsMessage, setNoRowsMessage] = useState('');

  useEffect(() => {
    if (competitorList === null) {
      return;
    }
    setNoRowsMessage(
      competitorList.length <= 0
        ? 'Kilpailuun ilmoittautuneita ei löytynyt'
        : 'Yhtään tulosta ei löytynyt haulla'
    );
  }, [competitorList]);

  const filterCompetitorList = ({
    name,
    team,
    category,
  }: ICompetitorListCompetitor) =>
    filter === '' ||
    name.toLowerCase().includes(filter.toLowerCase()) ||
    (team && team.toLowerCase().includes(filter.toLowerCase())) ||
    category.toLowerCase().includes(filter.toLowerCase());

  const filteredList = competitorList?.filter(filterCompetitorList) || null;

  return (
    <Container className="my-4 sm:my-10">
      <Title accent={raceName}>Ilmoittautuneet</Title>
      <div className="my-4">
        <Input
          id="haku"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          Etsi...
        </Input>
      </div>
      <Table
        columns={tableHeaders}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data={filteredList as any}
        noRowsMessage={noRowsMessage}
        indexColumnName="generatedListId"
      />
      <Button link="/">Takaisin</Button>
    </Container>
  );
};
