import { useState, useCallback } from 'react';

export const useForceUpdate = (): [
  forceUpdate: () => void,
  chagedState: Record<string, never>
] => {
  const [changedState, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  return [forceUpdate, changedState];
};
