import { Switch, Route } from 'react-router-dom';

import { CompetitorListContainer } from './containers/CompetitorList.container';
import { ResultsContainer } from './containers/Results.container';
import { ResultsLiveContainer } from './containers/Results.live.container';
import { SingleRaceContainer } from './containers/singleRace.container';
import { StartListContainer } from './containers/startList.container';
import { StartWatchContainer } from './containers/startWatch.container';
import { SocketStatusBarContainer } from './containers/Timing/SocketStatusBar.container';
import { TimingContextWrapper } from './Context/TimingContext.wrapper';

export const SingleRaceApp = () => {
  return (
    <Switch>
      <Route exact path="/kilpailu/:raceId">
        <SingleRaceContainer />
      </Route>
      <Route exact path="/kilpailu/:raceId/ilmoittautuneet">
        <CompetitorListContainer />
      </Route>
      <Route exact path="/kilpailu/:raceId/lahtolista">
        <StartListContainer />
      </Route>
      <Route exact path="/kilpailu/:raceId/tulokset">
        <ResultsContainer />
      </Route>
      <Route exact path="/kilpailu/:raceId/lahtokello">
        <TimingContextWrapper>
          <SocketStatusBarContainer />
          <StartWatchContainer />
        </TimingContextWrapper>
      </Route>
      <Route exact path="/kilpailu/:raceId/live">
        <TimingContextWrapper>
          <SocketStatusBarContainer />
          <ResultsLiveContainer />
        </TimingContextWrapper>
      </Route>
    </Switch>
  );
};
