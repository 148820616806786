import React from 'react';

import { Button } from '../../components/Button/button';
import { CategoryNavigation } from '../../components/CategoryNavigation/categoryNavigation';
import { RaceClock } from '../../components/Clock/RaceClock';
import { CompetitorStatus } from '../../components/competitorStatus/competitorStatus';
import { Container } from '../../components/container/container';
import { Loader } from '../../components/loader/loader';
import { Title } from '../../components/Title/title';
import { msToStr } from '../../services/time';

import {
  ICompetitorWithPositionResult,
  ResultsCategory,
} from './results.category';

interface IResultsProps {
  results: ICompetitorWithTime[] | null;
  categories: ICategory[] | null;
  raceName: string | number;
  className?: string;
  categoryNavigationClassName?: string;
  categoryTitleClassName?: string;
}

const parseResultRow = (
  { time, starttime, status, finishtime, ...competitor }: ICompetitorWithTime,
  index: number,
  arr: ICompetitorWithTime[]
): ICompetitorWithPositionResult => ({
  ...competitor,
  starttime,
  finishtime,
  status,
  position: time !== null ? index + 1 : null,
  timeDiff: time !== null ? time - (arr[0].time || 0) : null,
  time,
  result:
    time !== null ? (
      msToStr(time, 'H.MM.SS,f')
    ) : (
      <CompetitorStatus
        starttime={starttime}
        status={status}
        finishtime={finishtime}
      />
    ),
});

export const Results = ({
  results,
  categories,
  raceName,
  className = '',
  categoryNavigationClassName,
  categoryTitleClassName,
}: IResultsProps): JSX.Element => {
  if (categories === null || results === null) return <Loader />;

  const categoriesWithHighlight = categories.map<ICategory>(
    (currentCategory) => {
      const isHighligted = results
        .filter(({ category }) => category === currentCategory.key)
        .some(({ highlight }) => highlight);
      return { ...currentCategory, highlight: isHighligted };
    }
  );

  const now = new Date().getTime();

  return (
    <Container className={`mt-20 mb-16 sm:mt-16 sm:mb-10 ${className}`}>
      <CategoryNavigation
        categoryList={categoriesWithHighlight}
        className={categoryNavigationClassName}
      />
      <div className="flex justify-between">
        <Title accent={raceName}>Tulokset</Title>
        <RaceClock />
      </div>
      {categories.map((categoryInfo) => (
        <ResultsCategory
          key={categoryInfo.key}
          results={results
            .filter((competitor) => competitor.category === categoryInfo.key)
            .sort((a, b) => (a.time && a.time < (b.time || now) ? -1 : 1))
            .map(parseResultRow)}
          category={categoryInfo}
          titleClassName={categoryTitleClassName}
        />
      ))}
      <Button link="/">Takaisin</Button>
    </Container>
  );
};
