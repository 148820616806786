import React, { useState, useEffect } from 'react';

import { useRaceInfoContext } from '../Context/raceInfoContext';
import { useUpdateTimeSync } from '../hooks/useUpdateTimeSync';
import { Results } from '../pages/Results/results';
import { fetchResultList } from '../services/apiService';

export const ResultsContainer = (): JSX.Element => {
  const updateTimeSync = useUpdateTimeSync();
  const [raceInfo] = useRaceInfoContext();

  const [results, setResults] = useState<ICompetitorWithTime[] | null>(null);
  const [categories, setCategories] = useState<ICategory[] | null>(null);

  useEffect(() => {
    if (!raceInfo?.id) return;
    const getResults = async () => {
      const { competitors, categories: newCategories } = await fetchResultList(
        raceInfo.id
      );

      setCategories(newCategories || []);
      setResults(competitors || []);
    };
    getResults();
    updateTimeSync();
  }, [raceInfo?.id, updateTimeSync]);

  return (
    <Results
      results={results}
      categories={categories}
      raceName={raceInfo?.name || ''}
    />
  );
};
