import { useCallback, useState } from 'react';

import { useSetMultipleTimeout } from '../hooks/useSetTimeout';

import {
  useCompetitorValue,
  SET_FINISH_TIME,
  SET_STATUS,
  REMOVE_HIGHLIGHT,
  SET_DETAILS,
} from './competitorContext';
import {
  CompetitorContextActionType,
  CompetitorContextEmitableActionType,
} from './competitorContext.types';
import {
  useEmptyFinishTimeValue,
  ADD_EMPTY_FINISH_TIME,
  REMOVE_EMPTY_FINISH_TIME,
} from './emptyFinishTimeContext';
import {
  EmptyFinishTimeActionType,
  EmptyFinishTimeEmitableActionType,
} from './emptyFinishTimesContext.types';
import {
  useEmit,
  useDispatchSocketEvents,
  useSocketSocket,
  VERIFY_SENT_MESSAGE_RECEIVED,
  VERIFY_SENT_MESSAGE,
} from './socketContext';
import {
  SocketContextActionType,
  SocketReceiveableActionsType,
} from './socketContext.types';

const RESULT_HIGHLIGHT_DELAY =
  parseInt(process.env.REACT_APP_RESULT_HIGHLIGHT_DELAY || '', 10) || 5000;

const useRemoveCompetitorHighLight = (
  dispatch: React.Dispatch<CompetitorContextActionType>
): (({ bib }: { bib: number }) => void) => {
  const [startTimeout] = useSetMultipleTimeout();

  return ({ bib }) => {
    startTimeout(
      () => dispatch({ type: REMOVE_HIGHLIGHT, payload: bib }),
      RESULT_HIGHLIGHT_DELAY
    );
  };
};

export const useEmitAndDispatchCompetitors = (): ((
  action: CompetitorContextEmitableActionType
) => void) => {
  const [, dispatch] = useCompetitorValue();
  const emit = useEmit();
  const removeCompetitorHighLight = useRemoveCompetitorHighLight(dispatch);

  return (action) => {
    dispatch(action);
    emit(action);
    if (action.type === SET_FINISH_TIME) {
      removeCompetitorHighLight(action.payload);
    } else if (action.type === SET_DETAILS) {
      removeCompetitorHighLight(action.payload);
    } else if (action.type === SET_STATUS) {
      removeCompetitorHighLight(action.payload);
    }
  };
};

export const useEmitAndDispatchEmptyFinishTime = (): ((
  action: EmptyFinishTimeEmitableActionType
) => void) => {
  const [, dispatch] = useEmptyFinishTimeValue();
  const emit = useEmit();

  return (action) => {
    dispatch(action);
    emit(action);
  };
};

export const useListenCompetitorEvents = (): (() => void) => {
  const [, dispatch] = useCompetitorValue();
  const removeCompetitorHighLight = useRemoveCompetitorHighLight(dispatch);
  const [customHandler] = useState({
    SET_FINISH_TIME: removeCompetitorHighLight,
    SET_DETAILS: removeCompetitorHighLight,
    SET_STATUS: removeCompetitorHighLight,
  });
  const listenAndDispatch = useDispatchSocketEvents<{ bib: number }>(
    dispatch as React.Dispatch<SocketReceiveableActionsType>,
    customHandler
  );

  return useCallback(
    () => listenAndDispatch([SET_FINISH_TIME, SET_STATUS, SET_DETAILS]),
    [listenAndDispatch]
  );
};

export const useListenEmptyFinishTimeEvents = (): (() => void) => {
  const [, dispatch] = useEmptyFinishTimeValue();
  const listenAndDispatch = useDispatchSocketEvents<EmptyFinishTimeActionType>(
    dispatch as React.Dispatch<SocketReceiveableActionsType>
  );

  return useCallback(
    () => listenAndDispatch([ADD_EMPTY_FINISH_TIME, REMOVE_EMPTY_FINISH_TIME]),
    [listenAndDispatch]
  );
};

export const useListenVerifyMessageEvents = (): (() => void) => {
  const [, dispatch] = useSocketSocket();
  const listenAndDispatch = useDispatchSocketEvents<SocketContextActionType>(
    dispatch as React.Dispatch<SocketReceiveableActionsType>
  );

  return useCallback(
    () =>
      listenAndDispatch([VERIFY_SENT_MESSAGE_RECEIVED, VERIFY_SENT_MESSAGE]),
    [listenAndDispatch]
  );
};
