export const parseCompetitorStatus = (
  startTime: number,
  status: CompetitorStatusType
): 'Matkalla' | 'Lähdössä' | 'DNS' | 'DNF' => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;

  if (status) {
    return status;
  } else if (Date.now() + timezoneOffset < startTime) {
    return 'Lähdössä';
  }

  return 'Matkalla';
};

export const parseCompetitorOverallStatus = (
  startTime: number,
  status: CompetitorStatusType,
  time?: number
): 'Matkalla' | 'Lähdössä' | 'Maalissa' | 'DNS' | 'DNF' => {
  if (!status && time && time > 0) {
    return 'Maalissa';
  }

  return parseCompetitorStatus(startTime, status);
};
