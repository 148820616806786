import React from 'react';

import { Navigation } from '../../components/Navigation/Navigation';
import { useSettings } from '../../Context/settings/settingsContext';

export const TimingNavigation = (): JSX.Element => {
  const [{ raceToken }] = useSettings();
  const menuItems = [
    { name: 'Lähtö', link: `/ajanotto/${raceToken}/lahto` },
    { name: 'Maali', link: `/ajanotto/${raceToken}/maali` },
    { name: 'Tulokset', link: `/ajanotto/${raceToken}/tulokset` },
    { name: 'Tiedot', link: `/ajanotto/${raceToken}/tiedot` },
  ];
  return <Navigation menuItems={menuItems} />;
};
