import React, { FormEvent, useState } from 'react';
import { render } from 'react-dom';

import { Modal } from '../modal';
import { ModalActions } from '../modal.actions';
import { ModalHeader } from '../modal.header';

interface IModalFormProps {
  defaultOpen?: boolean;
  toggleButtonClassName?: string;
  accentColor?: 'red' | 'green' | 'brand';
  children?: React.ReactNode;
  label: string;
  modalOpenButtonLabel: string;
  onSubmit(event: FormEvent<HTMLFormElement>): void;
  onCancel?(): void;
  submitButtonLabel: string;
}

export const ModalForm = ({
  defaultOpen = false,
  toggleButtonClassName = '',
  children,
  label,
  modalOpenButtonLabel,
  onSubmit,
  onCancel = () => {},
  submitButtonLabel,
  accentColor,
}: IModalFormProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleToggleOpen = () => setIsOpen(!isOpen);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    onSubmit(event);
    handleToggleOpen();
  };
  const handleCancel = () => {
    handleToggleOpen();
    onCancel();
  };

  return (
    <Modal
      toggleButtonClassName={toggleButtonClassName}
      modalOpenButtonLabel={modalOpenButtonLabel}
      toggleOpen={handleToggleOpen}
      isOpen={isOpen}
      accentColor={accentColor}
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader label={label}>{children}</ModalHeader>
        <ModalActions
          submitButtonLabel={submitButtonLabel}
          onCancel={handleCancel}
          onConfirm={() => {}}
        />
      </form>
    </Modal>
  );
};

export const injectModalForm = ({
  children,
  label,
  modalOpenButtonLabel,
  onSubmit,
  onCancel = () => {},
  submitButtonLabel,
  accentColor,
}: IModalFormProps): void => {
  const removeThisElement = () =>
    document.getElementById('modal-confirm')?.remove();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    onSubmit(event);
    removeThisElement();
  };

  const handleCancel = () => {
    removeThisElement();
    onCancel();
  };

  const targetDiv = document.createElement('div');
  targetDiv.id = 'modal-confirm';
  document.body.appendChild(targetDiv);

  render(
    <ModalForm
      children={children}
      label={label}
      modalOpenButtonLabel={modalOpenButtonLabel}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      submitButtonLabel={submitButtonLabel}
      accentColor={accentColor}
      toggleButtonClassName="hidden"
      defaultOpen={true}
    />,
    targetDiv
  );
};
