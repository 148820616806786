import React from 'react';

import { ExternalLink } from './raceList.box.externalLink';
import { InternalLink } from './raceList.box.internalLink';
import { RaceListRaceInfo } from './raceList.raceInfo';

interface IRaceLinkBox {
  id: number;
  name: string;
  date: string;
  description?: string;
  msg?: string;
  links: IRaceInfoLink[];
  live?: boolean;
  competitorList?: boolean;
  startList?: boolean;
  resultList?: boolean;
}

const buttonClasses = 'flex justify-between';

export const RaceListBox = ({
  id,
  name,
  date = '',
  description = '',
  msg,
  links = [],
  live = false,
  competitorList = false,
  startList = false,
  resultList = false,
}: IRaceLinkBox): JSX.Element => (
  <div className="bg-white p-4 flex flex-col justify-between gap-4 shadow-sm rounded-sm">
    <div>
      <h2 className="text-lg">{name}</h2>
      {(date || description || msg) && (
        <RaceListRaceInfo date={date} description={description} msg={msg} />
      )}
    </div>
    <div className="flex flex-col gap-2">
      {links &&
        links.map(({ name: linkName, url, mimetype }) => (
          <ExternalLink
            key={url}
            link={url}
            mimeType={mimetype}
            className={buttonClasses}
          >
            {linkName}
          </ExternalLink>
        ))}
      {competitorList && (
        <InternalLink
          link={`/kilpailu/${id}/ilmoittautuneet`}
          className={buttonClasses}
        >
          Näytä ilmoittautuneet
        </InternalLink>
      )}
      {startList && (
        <InternalLink
          link={`/kilpailu/${id}/lahtolista`}
          className={buttonClasses}
        >
          Näytä lähtölista
        </InternalLink>
      )}
      {resultList && (
        <InternalLink
          link={`/kilpailu/${id}/tulokset`}
          className={buttonClasses}
        >
          Näytä tulokset
        </InternalLink>
      )}
      {live && (
        <InternalLink link={`/kilpailu/${id}/live`} className={buttonClasses}>
          Live tulokset
        </InternalLink>
      )}
    </div>
  </div>
);
