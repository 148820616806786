import { useCallback, useEffect, useState } from 'react';

import { useCompetitorValue } from '../../Context/competitorContext';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useRaceUnixtime } from '../../hooks/useRaceTime';
import {
  useSetMultipleTimeout,
  useSetTimeout,
} from '../../hooks/useSetTimeout';
import { useWakeLock } from '../../hooks/useWakeLock';
import { Start } from '../../pages/Start/Start';
import { useTimeToStart } from '../../pages/Start/useTimeToStart';
import { playLongBeep, playShortBeep } from '../../services/soundService';

const SHOW_COMPETITOR_AFTER_START_MS = 5000;
const COMPETITOR_COUNT = 4;

const usePlayStartBeeps = () => {
  const [startTimeout, clearTimeouts] = useSetMultipleTimeout();

  return useCallback(
    (timeToStart: number) => {
      clearTimeouts();

      if (timeToStart < 0) {
        return;
      }

      if (timeToStart > 3000) {
        startTimeout(playShortBeep, timeToStart - 3000);
      }
      if (timeToStart > 2000) {
        startTimeout(playShortBeep, timeToStart - 2000);
      }
      if (timeToStart > 1000) {
        startTimeout(playShortBeep, timeToStart - 1000);
      }
      startTimeout(playLongBeep, timeToStart);
    },
    [clearTimeouts, startTimeout]
  );
};

export const TimingStartWatchContainer = (): JSX.Element => {
  const getTimeToStart = useTimeToStart();
  const getRaceTime = useRaceUnixtime();
  const [startList, setStartList] = useState<ICompetitor[] | null>(null);

  const [forceUpdate, forceUpdatedState] = useForceUpdate();
  const [startTimeout] = useSetTimeout();
  const playStartBeeps = usePlayStartBeeps();

  const [{ competitors }] = useCompetitorValue();

  useWakeLock();

  useEffect(() => {
    if (!competitors) {
      return;
    }
    const newStartList =
      competitors
        ?.filter(
          (competitor) =>
            getRaceTime() <
            competitor.starttime + SHOW_COMPETITOR_AFTER_START_MS
        )
        .slice(0, COMPETITOR_COUNT) || [];

    if (newStartList.length > 0) {
      const firstStartTime = newStartList[0].starttime;
      const timeToNextStart = getTimeToStart(firstStartTime);
      playStartBeeps(timeToNextStart);

      const setHighlightAfterStart = () => {
        setStartList(
          (currentStartList) =>
            currentStartList?.map(({ starttime, ...competitor }) => ({
              ...competitor,
              starttime,
              highlight: starttime === firstStartTime,
            })) || []
        );
      };

      startTimeout(setHighlightAfterStart, timeToNextStart);
      startTimeout(
        forceUpdate,
        timeToNextStart + SHOW_COMPETITOR_AFTER_START_MS
      );
    }
    startTimeout(forceUpdate, 1000);

    setStartList(newStartList);

    return () => {};
  }, [
    competitors,
    forceUpdate,
    startTimeout,
    forceUpdatedState,
    getRaceTime,
    getTimeToStart,
    playStartBeeps,
  ]);

  return <Start startList={startList} />;
};
