import React from 'react';

interface IModalHeaderProps {
  label: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

export const ModalHeader = ({
  label,
  children,
  icon,
}: IModalHeaderProps): JSX.Element => {
  return (
    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div className="sm:flex sm:items-start">
        {icon && icon}
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            className="text-lg leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            {label}
          </h3>
          {children && (
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500">{children}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
