import React, { useState, useEffect } from 'react';

import { useRaceInfoContext } from '../Context/raceInfoContext';
import { RaceList } from '../pages/RaceList/raceList';

export const SingleRaceContainer = (): JSX.Element => {
  const [rawRaceInfo] = useRaceInfoContext();
  const [raceInfo, setRaceInfo] = useState<IRaceInfoWrapper[] | null>(null);

  useEffect(() => {
    setRaceInfo(rawRaceInfo ? [{ title: '', races: [rawRaceInfo] }] : []);
  }, [rawRaceInfo]);

  return <RaceList raceGroupList={raceInfo} />;
};
