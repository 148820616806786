import { HubConnectionBuilder } from '@microsoft/signalr';
import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
} from 'react';

import { useSettings } from './settings/settingsContext';
import {
  ISocketState,
  SocketContextType,
  SocketContextActionType,
  EmitableActionsType,
  SocketReceiveableActionsType,
} from './socketContext.types';

export const CLOSE_SOCKET = 'CLOSE_SOCKET';
export const SUBSCRIBE_RACE = 'SUBSCRIBE_RACE';
export const AUTHENTICATE_RACE = 'AUTHENTICATE_RACE';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_RECONNECTING = 'SOCKET_RECONNECTING';
export const SOCKET_RECONNECTED = 'SOCKET_RECONNECTED';
export const SOCKET_FAILED = 'SOCKET_FAILED';
export const ADD_SENT_MESSAGE = 'ADD_SENT_MESSAGE';
export const VERIFY_SENT_MESSAGE_RECEIVED = 'VERIFY_MESSAGE_RECEIVED';
export const VERIFY_SENT_MESSAGE = 'VERIFY_MESSAGE';

const WEBSOCKET_ENDPOINT = process.env.REACT_APP_WEBSOCKET || '';

interface ISocketProviderProps {
  children: React.ReactNode;
}

const initialState: ISocketState = {
  socket: new HubConnectionBuilder()
    .withUrl(WEBSOCKET_ENDPOINT)
    .withAutomaticReconnect()
    .build(),
  unverifiedMessages: [],
};

const SocketContext = createContext<SocketContextType>([
  initialState,
  () => null,
]);

export const useSocketSocket = (): SocketContextType =>
  useContext(SocketContext);

const rootReducer = (
  state: ISocketState,
  action: SocketContextActionType
): ISocketState => {
  const { socket } = state;
  switch (action.type) {
    case SUBSCRIBE_RACE:
    case AUTHENTICATE_RACE:
      socket
        .start()
        .then(() => {
          socket.invoke(action.type, action.payload);
          if (typeof action.onConnected === 'function') action.onConnected();
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err.toString()));
      return { ...state, socket };
    case SOCKET_RECONNECTING:
      socket.onreconnecting(action.payload);
      return { ...state, socket };
    case SOCKET_RECONNECTED:
      socket.onreconnected(action.payload);
      return { ...state, socket };
    case SOCKET_FAILED:
      socket.onclose(action.payload);
      return { ...state, socket };
    case CLOSE_SOCKET:
      socket.stop();
      return { ...state, socket };
    case ADD_SENT_MESSAGE:
      // eslint-disable-next-line no-case-declarations
      const unverifiedMessages = state.unverifiedMessages.some(
        ({ value }) =>
          value.type === action.payload.value.type &&
          JSON.stringify(action.payload.value) === JSON.stringify(value)
      )
        ? state.unverifiedMessages
        : state.unverifiedMessages.concat(action.payload);
      return { ...state, unverifiedMessages };
    case VERIFY_SENT_MESSAGE_RECEIVED:
      return {
        ...state,
        unverifiedMessages: state.unverifiedMessages.map((failedMessage) => {
          const {
            value: { payload: targetAction },
          } = failedMessage;
          return JSON.stringify(action.payload) !== JSON.stringify(targetAction)
            ? failedMessage
            : { ...failedMessage, status: 'received' };
        }),
      };
    case VERIFY_SENT_MESSAGE:
      return {
        ...state,
        unverifiedMessages: state.unverifiedMessages.filter(
          ({ value: { payload: targetAction } }) =>
            JSON.stringify(action.payload) !== JSON.stringify(targetAction)
        ),
      };
    default:
      return { ...state };
  }
};

export const SocketProvider = ({
  children,
}: ISocketProviderProps): JSX.Element => (
  <SocketContext.Provider value={useReducer(rootReducer, initialState)}>
    {children}
  </SocketContext.Provider>
);

export const useEmit = (): ((action: EmitableActionsType) => void) => {
  const [{ socket }, dispatchSocket] = useSocketSocket();
  const [{ raceToken }] = useSettings();

  return (action) => {
    const { type, payload } = action;
    socket.invoke(type, raceToken, JSON.stringify({ payload }));
    dispatchSocket({
      type: ADD_SENT_MESSAGE,
      payload: {
        value: action,
        status: 'unverified',
        sendTime: new Date().getTime(),
      },
    });
  };
};

export const useDispatchSocketEvents = <K extends unknown>(
  dispatch: React.Dispatch<SocketReceiveableActionsType>,
  customHandler?: { [key in string]: (payload: K) => void }
): ((events: SocketReceiveableActionsType['type'][]) => void) => {
  const [{ socket }] = useSocketSocket();

  return useCallback(
    (events) => {
      events.forEach((event) => {
        socket.on(event, (payloadStr) => {
          const { payload } = JSON.parse(payloadStr);
          dispatch({
            type: event,
            payload,
          });
          if (customHandler && event in customHandler) {
            customHandler[event](payload);
          }
        });
      });
    },
    [customHandler, dispatch, socket]
  );
};
