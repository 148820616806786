import React from 'react';
import { NavLink } from 'react-router-dom';

import { useTeamIdQueryString } from '../../hooks/useTeamIdQueryString';

export interface ImenuItem {
  name: string;
  link: string;
}

interface INavigationMenuProps {
  menuItems: ImenuItem[];
  isOpen: boolean;
  closeMenu(): void;
}

export const NavigationMenu = ({
  menuItems,
  isOpen,
  closeMenu,
}: INavigationMenuProps): JSX.Element => {
  const queryString = useTeamIdQueryString();

  return (
    <ul
      className={`${
        isOpen ? 'flex' : 'hidden'
      } fixed top-0 bottom-12 left-0 h-auto w-full bg-gray-200 flex flex-col gap-4 items-center justify-center text-center`}
    >
      {menuItems.map(({ name, link }) => (
        <li key={link}>
          <NavLink
            to={link + queryString}
            onClick={closeMenu}
            activeClassName="font-bold"
          >
            {name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
