import React from 'react';

import { useCompetitorValue } from '../../Context/competitorContext';
import { useRaceInfoContext } from '../../Context/raceInfoContext';
import { Results } from '../../pages/Results/results';

export const TimingResultsContainer = (): JSX.Element => {
  const [{ competitors, categories }] = useCompetitorValue();
  const [raceInfo] = useRaceInfoContext();

  return (
    <Results
      results={competitors}
      categories={categories}
      raceName={raceInfo?.name || ''}
      className="mt-24 !mb-16"
      categoryNavigationClassName="!top-4"
      categoryTitleClassName="pt-14 -mt-14"
    />
  );
};
