import { useEffect, useCallback } from 'react';

import {
  useCompetitorValue,
  INITIAL_DATA,
} from '../../Context/competitorContext';
import { CompetitorContextActionType } from '../../Context/competitorContext.types';
import { useEmptyFinishTimeValue } from '../../Context/emptyFinishTimeContext';
import { EmptyFinishTimeActionType } from '../../Context/emptyFinishTimesContext.types';
import { useRaceInfoContext } from '../../Context/raceInfoContext';
import { useSettings } from '../../Context/settings/settingsContext';
import {
  SET_RACE_TOKEN,
  SET_CONNECTION_STATUS,
  CONNECTION_STATUS,
  SET_RACE_ID,
} from '../../Context/settings/settingsContext.constants';
import {
  useSocketSocket,
  CLOSE_SOCKET,
  AUTHENTICATE_RACE,
  SOCKET_FAILED,
  SOCKET_RECONNECTING,
  SOCKET_RECONNECTED,
} from '../../Context/socketContext';
import {
  useListenCompetitorEvents,
  useListenEmptyFinishTimeEvents,
  useListenVerifyMessageEvents,
} from '../../Context/socketContext.helper';
import { useUpdateTimeSync } from '../../hooks/useUpdateTimeSync';
import { fetchResultList } from '../../services/apiService';

interface ITimingContainerProps {
  children: React.ReactNode;
}

const fetchCompetitorData = async (
  dispatchCompetitor: React.Dispatch<CompetitorContextActionType>,
  dispatchEmptyFinishTime: React.Dispatch<EmptyFinishTimeActionType>,
  raceToken: string
) => {
  const { competitors, categories, emptyFinishTimes } = await fetchResultList(
    raceToken
  );

  dispatchCompetitor({
    type: INITIAL_DATA,
    payload: { competitors, categories },
  });
  dispatchEmptyFinishTime({ type: INITIAL_DATA, payload: emptyFinishTimes });
};

export const TimingContainer = ({
  children,
}: ITimingContainerProps): React.ReactNode => {
  const updateTimeSync = useUpdateTimeSync();
  const [, dispatchSocket] = useSocketSocket();
  const [, dispatchCompetitor] = useCompetitorValue();
  const [, dispatchEmptyFinishTime] = useEmptyFinishTimeValue();
  const [, dispatchSettings] = useSettings();
  const listenCompetitorEvents = useListenCompetitorEvents();
  const listenEmptyFinishTimeEvents = useListenEmptyFinishTimeEvents();
  const listenVerifyMessageEvents = useListenVerifyMessageEvents();
  const [raceInfo] = useRaceInfoContext();

  const onConnected = useCallback(() => {
    dispatchSettings({
      type: SET_CONNECTION_STATUS,
      payload: CONNECTION_STATUS.CONNECTED,
    });
  }, [dispatchSettings]);

  const onConnecting = useCallback(() => {
    dispatchSettings({
      type: SET_CONNECTION_STATUS,
      payload: CONNECTION_STATUS.WAITING,
    });
  }, [dispatchSettings]);

  const onConnectLost = useCallback(() => {
    dispatchSettings({
      type: SET_CONNECTION_STATUS,
      payload: CONNECTION_STATUS.FAILED,
    });
  }, [dispatchSettings]);

  const registerBeforeUnload = () => {
    window.onbeforeunload = () =>
      'Haluatko varmasti poistua, kaikki tiedot poistetaan?';
  };

  useEffect(() => {
    if (!raceInfo?.raceToken) return;

    if (process.env.NODE_ENV === 'production') {
      registerBeforeUnload();
    }
    onConnecting();
    dispatchSocket({
      type: AUTHENTICATE_RACE,
      payload: raceInfo?.raceToken || '',
      onConnected,
    });
    dispatchSocket({ type: SOCKET_RECONNECTING, payload: onConnecting });
    dispatchSocket({ type: SOCKET_RECONNECTED, payload: onConnected });
    dispatchSocket({ type: SOCKET_FAILED, payload: onConnectLost });
    listenCompetitorEvents();
    listenEmptyFinishTimeEvents();
    listenVerifyMessageEvents();
    fetchCompetitorData(
      dispatchCompetitor,
      dispatchEmptyFinishTime,
      raceInfo?.raceToken || ''
    );
    updateTimeSync();

    return () => {
      window.onbeforeunload = null;
      dispatchSocket({ type: CLOSE_SOCKET });
    };
  }, [
    dispatchCompetitor,
    dispatchEmptyFinishTime,
    dispatchSocket,
    listenCompetitorEvents,
    listenEmptyFinishTimeEvents,
    listenVerifyMessageEvents,
    onConnectLost,
    onConnected,
    onConnecting,
    raceInfo?.raceToken,
    updateTimeSync,
  ]);

  useEffect(() => {
    if (!raceInfo) return;

    dispatchSettings({
      type: SET_RACE_TOKEN,
      payload: raceInfo?.raceToken || '',
    });
    dispatchSettings({
      type: SET_RACE_ID,
      payload: raceInfo.id,
    });
  }, [dispatchSettings, raceInfo]);

  return children;
};
