import React, { createContext, useContext, useReducer } from 'react';

import {
  CompetitorContextActionType,
  CompetitorContextType,
  CompetitorStateType,
} from './competitorContext.types';

export const REMOVE_HIGHLIGHT = 'REMOVE_HIGHLIGHT';
export const INITIAL_DATA = 'INITIAL_DATA';
export const SET_FINISH_TIME = 'SET_FINISH_TIME';
export const SET_STATUS = 'SET_STATUS';
export const SET_DETAILS = 'SET_DETAILS';
interface ICompetitorProviderProps {
  children: React.ReactNode;
}

const initialState: CompetitorStateType = {
  competitors: null,
  categories: null,
};

const setFinishTime = (
  { competitors: oldCompetitors, ...rest }: CompetitorStateType,
  { bib, finishTime }: { bib: number; finishTime: number }
) => {
  const isFinished = finishTime > 0;
  const currentTimestamp = Date.now();

  const competitors =
    oldCompetitors
      ?.map((competitor) =>
        competitor.bib !== bib
          ? competitor
          : {
              ...competitor,
              finishtime: finishTime,
              time: isFinished ? finishTime - competitor.starttime : null,
              highlight: true,
            }
      )
      .sort((a, b) => a.bib - b.bib)
      .sort(
        (a, b) => (a.time || currentTimestamp) - (b.time || currentTimestamp)
      ) || null;

  return {
    ...rest,
    competitors,
  };
};

export const CompetitorContext = createContext<CompetitorContextType>([
  initialState,
  () => null,
]);

export const useCompetitorValue = (): CompetitorContextType =>
  useContext(CompetitorContext);

const rootReducer = (
  state: CompetitorStateType,
  action: CompetitorContextActionType
) => {
  switch (action.type) {
    case INITIAL_DATA:
      return {
        ...state,
        competitors: state.competitors
          ? state.competitors.concat(action.payload.competitors)
          : action.payload.competitors,
        categories: state.categories
          ? state.categories.concat(action.payload.categories)
          : action.payload.categories,
      };

    case SET_FINISH_TIME:
      return setFinishTime(state, action.payload);

    case SET_STATUS:
      return {
        ...state,
        competitors:
          state.competitors?.map((competitor) =>
            competitor.bib !== action.payload.bib
              ? competitor
              : {
                  ...competitor,
                  status: action.payload.status,
                  highlight: true,
                }
          ) || null,
      };

    case SET_DETAILS:
      return {
        ...state,
        competitors:
          state.competitors?.map((competitor) =>
            competitor.bib !== action.payload.bib
              ? competitor
              : { ...competitor, ...action.payload, highlight: true }
          ) || null,
      };

    case REMOVE_HIGHLIGHT:
      return {
        ...state,
        competitors:
          state.competitors?.map((competitor) =>
            competitor.bib !== action.payload
              ? competitor
              : { ...competitor, highlight: false }
          ) || null,
      };
    default:
      return state;
  }
};

export const CompetitorProvider = ({
  children,
}: ICompetitorProviderProps): JSX.Element => (
  <CompetitorContext.Provider value={useReducer(rootReducer, initialState)}>
    {children}
  </CompetitorContext.Provider>
);
