import React from 'react';

interface IRadioButtonProps {
  checked?: boolean;
  children: string;
  id: string;
  isDisabled?: boolean;
  value: string;
}

interface IRadioButtonGroupProps {
  children: React.ReactNode;
  label?: string;
}

export const RadioButton = ({
  checked,
  children,
  id,
  isDisabled,
  value,
}: IRadioButtonProps): JSX.Element => {
  return (
    <div className="w-full">
      <label>
        <input
          disabled={isDisabled}
          type="radio"
          name={id}
          className="hidden peer"
          defaultChecked={checked}
          value={value}
        />
        <span className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 shadow-sm focus-within:ring-2 focus:ring-inset focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed hover:text-gray-500 focus:ring-blue-500 peer-checked:text-white peer-checked:bg-brand-color peer-checked:hover:bg-opacity-90 peer-checked:active:bg-opacity-90 peer-checked:focus:ring-blue-500 peer-disabled:opacity-70 peer-disabled:cursor-not-allowed">
          {children}
        </span>
      </label>
    </div>
  );
};

export const RadioButtonGroup = ({
  label,
  children,
}: IRadioButtonGroupProps): JSX.Element => (
  <div>
    {label && (
      <p className="block text-xs font-semibold leading-5 tracking-wider text-gray-500 uppercase">
        {label}
      </p>
    )}
    <div className="flex flex-grow mt-3 overflow-hidden rounded-md">
      {children}
    </div>
  </div>
);
