import React from 'react';

import { useCompetitorValue } from '../../Context/competitorContext';
import { CompetitorContextEmitableActionType } from '../../Context/competitorContext.types';
import { useRaceInfoContext } from '../../Context/raceInfoContext';
import { useSettings } from '../../Context/settings/settingsContext';
import { useEmitAndDispatchCompetitors } from '../../Context/socketContext.helper';
import { RaceInfo } from '../../pages/RaceInfo/raceInfo';

export const RaceInfoContainer = (): JSX.Element => {
  const dispatchCompetitor = useEmitAndDispatchCompetitors();

  const [{ competitors, categories }] = useCompetitorValue();
  const [{ raceToken, timeSync }] = useSettings();
  const [raceInfo] = useRaceInfoContext();

  const handleUpdateCompetitor = (
    dispatchData: CompetitorContextEmitableActionType
  ): void => dispatchCompetitor(dispatchData);
  return (
    <RaceInfo
      competitors={competitors?.sort((a, b) => a.bib - b.bib) || null}
      raceName={raceInfo?.name || ''}
      raceToken={raceToken || ''}
      allCategories={categories || []}
      updateCompetitor={handleUpdateCompetitor}
      timeDiff={timeSync?.timeDiff}
      ping={timeSync?.ping}
    />
  );
};
