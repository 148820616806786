import React, { useEffect } from 'react';

import { StatusColorBar } from '../../components/StatusColorBar/StatusColorBar';
import { STATUS } from '../../components/StatusColorBar/StatusColorBar.constants';
import { useSettings } from '../../Context/settings/settingsContext';
import { CONNECTION_STATUS } from '../../Context/settings/settingsContext.constants';
import { useSocketSocket } from '../../Context/socketContext';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';

const MAX_PROSESING_TIME = parseInt(
  process.env.REACT_APP_WEBSOCKET_MAX_PROCESSING_TIME_SECONDS || '10',
  10
);

const mapConnectionStatus = {
  [CONNECTION_STATUS.CONNECTED]: STATUS.GOOD,
  [CONNECTION_STATUS.WAITING]: STATUS.WARNING,
  [CONNECTION_STATUS.FAILED]: STATUS.DANGER,
};

export const SocketStatusBarContainer = (): JSX.Element => {
  const [startRefresh, stopRefresh] = useAutoRefresh(5000);
  const [{ connectionStatus }] = useSettings();
  const [{ unverifiedMessages }] = useSocketSocket();

  useEffect(() => {
    startRefresh();
    return () => stopRefresh();
  }, [startRefresh, stopRefresh]);

  const allowedSendTime = new Date().getTime() - MAX_PROSESING_TIME * 1000;

  const currentStatus =
    unverifiedMessages.filter(
      ({ sendTime, status }) =>
        status === 'unverified' || sendTime < allowedSendTime
    ).length > 0
      ? STATUS.DANGER
      : mapConnectionStatus[connectionStatus || ''];

  return <StatusColorBar status={currentStatus} />;
};
