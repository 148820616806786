import { useEffect } from 'react';

type WakeLockSentinel = {
  release: () => Promise<void>;
  addEventListener: (event: string, callback: () => void) => void;
};

let wakeLock: WakeLockSentinel | null = null;

// Function to request the wake lock
async function requestWakeLock() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wakeLock = await (navigator as any).wakeLock.request('screen');
    wakeLock?.addEventListener('release', () => {
      console.log('Wake Lock was released');
    });
    console.log('Wake Lock is active');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.error(`${err.name}, ${err.message}`);
  }
}

// Function to release the wake lock
async function releaseWakeLock() {
  if (wakeLock !== null) {
    await wakeLock.release();
    wakeLock = null;
  }
}

export const useWakeLock = (): void => {
  useEffect(() => {
    requestWakeLock();

    return () => {
      releaseWakeLock();
    };
  }, []);
};
