import React from 'react';

import { CompetitorProvider } from './competitorContext';
import { EmptyFinishTimeProvider } from './emptyFinishTimeContext';
import { SettingsProvider } from './settings/settingsContext';
import { SocketProvider } from './socketContext';

interface ITimingContextWrapperProps {
  children: React.ReactNode;
}

export const TimingContextWrapper = ({
  children,
}: ITimingContextWrapperProps): JSX.Element => (
  <SettingsProvider>
    <SocketProvider>
      <CompetitorProvider>
        <EmptyFinishTimeProvider>{children}</EmptyFinishTimeProvider>
      </CompetitorProvider>
    </SocketProvider>
  </SettingsProvider>
);
