import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useCompetitorValue, INITIAL_DATA } from '../Context/competitorContext';
import { CompetitorContextActionType } from '../Context/competitorContext.types';
import { useSettings } from '../Context/settings/settingsContext';
import {
  SET_RACE_ID,
  SET_CONNECTION_STATUS,
  CONNECTION_STATUS,
} from '../Context/settings/settingsContext.constants';
import {
  useSocketSocket,
  CLOSE_SOCKET,
  SUBSCRIBE_RACE,
  SOCKET_FAILED,
  SOCKET_RECONNECTING,
  SOCKET_RECONNECTED,
} from '../Context/socketContext';
import { useListenCompetitorEvents } from '../Context/socketContext.helper';
import { useUpdateTimeSync } from '../hooks/useUpdateTimeSync';
import { fetchResultList } from '../services/apiService';

import { TimingResultsContainer } from './Timing/Timing.Results.container';

const fetchCompetitorData = async (
  dispatchCompetitor: React.Dispatch<CompetitorContextActionType>,
  raceId: string
) => {
  const { competitors, categories } = await fetchResultList(raceId);

  dispatchCompetitor({
    type: INITIAL_DATA,
    payload: { competitors, categories },
  });
};

export const ResultsLiveContainer = (): JSX.Element => {
  const updateTimeSync = useUpdateTimeSync();

  const { raceId } = useParams<{ raceId: string }>();

  const [, dispatchSocket] = useSocketSocket();
  const [, dispatchCompetitor] = useCompetitorValue();
  const [, dispatchSettings] = useSettings();
  const listenCompetitorEvents = useListenCompetitorEvents();

  const onConnected = useCallback(() => {
    dispatchSettings({
      type: SET_CONNECTION_STATUS,
      payload: CONNECTION_STATUS.CONNECTED,
    });
  }, [dispatchSettings]);

  const onConnecting = useCallback(() => {
    dispatchSettings({
      type: SET_CONNECTION_STATUS,
      payload: CONNECTION_STATUS.WAITING,
    });
  }, [dispatchSettings]);

  const onConnectLost = useCallback(() => {
    dispatchSettings({
      type: SET_CONNECTION_STATUS,
      payload: CONNECTION_STATUS.FAILED,
    });
  }, [dispatchSettings]);

  useEffect(() => {
    onConnecting();
    dispatchSocket({
      type: SUBSCRIBE_RACE,
      payload: raceId,
      onConnected,
    });
    dispatchSocket({ type: SOCKET_RECONNECTING, payload: onConnecting });
    dispatchSocket({ type: SOCKET_RECONNECTED, payload: onConnected });
    dispatchSocket({ type: SOCKET_FAILED, payload: onConnectLost });
    listenCompetitorEvents();
    fetchCompetitorData(dispatchCompetitor, raceId);
    updateTimeSync();

    return () => {
      dispatchSocket({ type: CLOSE_SOCKET });
    };
  }, [
    dispatchCompetitor,
    dispatchSocket,
    listenCompetitorEvents,
    onConnectLost,
    onConnected,
    onConnecting,
    raceId,
    updateTimeSync,
  ]);

  useEffect(() => {
    dispatchSettings({
      type: SET_RACE_ID,
      payload: parseInt(raceId),
    });
  }, [dispatchSettings, raceId]);

  return <TimingResultsContainer />;
};
