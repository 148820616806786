import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { RaceListContainer } from './containers/raceList.container';
import { RaceInfoProvider, RaceInfoUpdater } from './Context/raceInfoContext';
import { TeamInfoProvider } from './Context/teamInfoContext';
import { NotFound } from './pages/NotFound/notFound';
import { RaceTokenForm } from './pages/RaceTokenForm/raceTokenForm';
import { SingleRaceApp } from './SingleRace.App';
import { TimingApp } from './Timing.App';

const SITE_BASE_PATH = process.env.PUBLIC_URL || '/';

export const App = () => (
  <Router basename={SITE_BASE_PATH}>
    <TeamInfoProvider>
      <RaceInfoProvider>
        <Switch>
          <Route exact path="/" component={RaceListContainer} />
          <Route path="/kilpailu/:raceId">
            <RaceInfoUpdater>
              <SingleRaceApp />
            </RaceInfoUpdater>
          </Route>

          <Route exact path="/ajanotto/" component={RaceTokenForm} />
          <Route path="/ajanotto/:raceId">
            <RaceInfoUpdater>
              <TimingApp />
            </RaceInfoUpdater>
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </RaceInfoProvider>
    </TeamInfoProvider>
  </Router>
);
