import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

import './assets/tailwind.css';

ReactDOM.render(<App />, document.getElementById('root'));

// serviceWorker.register();
serviceWorker.unregister();
