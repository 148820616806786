import React from 'react';

import { useSettings } from '../../Context/settings/settingsContext';
import { useSocketSocket } from '../../Context/socketContext';
import { ExportData } from '../../pages/ExportData/exportData';

export const ExportFailedMessagesContainer = (): JSX.Element => {
  const [{ unverifiedMessages }] = useSocketSocket();
  const [{ raceId }] = useSettings();

  return (
    <ExportData
      data={{
        raceId: raceId || null,
        data: unverifiedMessages.map(({ value, ...rest }) => ({
          ...value,
          ...rest,
        })),
      }}
      fileNamePrefix={`race-${raceId}_failed-data`}
    />
  );
};
