import { useCallback } from 'react';

import { useSettings } from '../Context/settings/settingsContext';

const timeZoneOffsetMs = new Date().getTimezoneOffset() * -1 * 60 * 1000;

export const useRaceUnixtime = (): (() => number) => {
  const [{ timeSync }] = useSettings();

  return useCallback(
    () => Date.now() + timeZoneOffsetMs + (timeSync?.timeDiff ?? 0),
    [timeSync]
  );
};
