import React from 'react';

interface ITitleProps {
  children: React.ReactNode;
  accent?: React.ReactNode;
  className?: string;
}

export const Title = ({
  children,
  accent,
  className = '',
}: ITitleProps): JSX.Element => {
  return (
    <h1 className={`${className} text-3xl sm:text-4xl text-gray-900`}>
      {children} {accent && <p className="text-xl text-gray-600">{accent}</p>}
    </h1>
  );
};
