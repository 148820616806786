import React, { useEffect, useState } from 'react';

import { Button } from '../../components/Button/button';
import { RaceClock } from '../../components/Clock/RaceClock';
import { CompetitorStatus } from '../../components/competitorStatus/competitorStatus';
import { Container } from '../../components/container/container';
import { Icon } from '../../components/Icon/icon';
import { Input } from '../../components/input/input';
import { Loader } from '../../components/loader/loader';
import { IRootColumn, Table } from '../../components/Table/table';
import {
  CATEGORY_CLASSES,
  NAME_CLASSES,
  NUMBER_CLASSES,
  TIME_CLASSES,
} from '../../components/Table/table.constants';
import { Title } from '../../components/Title/title';
import { CompetitorContextEmitableActionType } from '../../Context/competitorContext.types';
import { useSetTimeout } from '../../hooks/useSetTimeout';

import { RaceInfoModal } from './raceInfo.modal';

interface IRaceInfoProps {
  competitors: ICompetitor[] | null;
  raceName: string;
  raceToken: string;
  allCategories: ICategory[];
  updateCompetitor(dispatchData: CompetitorContextEmitableActionType): void;
  timeDiff?: number;
  ping?: number;
}

interface IRaceInfoCompetitor extends ICompetitor {
  computedStatus: React.ReactNode;
  edit: React.ReactNode;
}

const columns: IRootColumn[] = [
  {
    key: 'bib',
    title: 'Nro',
    widthClassName: NUMBER_CLASSES,
  },
  {
    key: 'name',
    title: 'Nimi',
    widthClassName: NAME_CLASSES,
    accent: { key: 'team', title: 'Seura' },
  },
  {
    key: 'category',
    title: 'Sarja',
    widthClassName: CATEGORY_CLASSES,
  },
  {
    key: 'computedStatus',
    title: 'Status',
    widthClassName: TIME_CLASSES,
  },

  {
    key: 'edit',
    title: 'Muokkaa',
    widthClassName: TIME_CLASSES,
  },
];

export const RaceInfo = ({
  competitors,
  raceName,
  raceToken,
  allCategories,
  updateCompetitor,
  timeDiff,
  ping,
}: IRaceInfoProps): JSX.Element => {
  const [filter, setFilter] = useState('');
  const [competitorsWithComputedStatus, setCompetitorsWithComputedStatus] =
    useState<IRaceInfoCompetitor[]>([]);
  const [filteredCompetitors, setFilteredCompetitors] = useState<
    IRaceInfoCompetitor[]
  >([]);
  const [startTimeout] = useSetTimeout();

  useEffect(() => {
    if (!competitors) return;

    setCompetitorsWithComputedStatus(
      competitors.map<IRaceInfoCompetitor>(
        ({ starttime, status, finishtime, ...competitor }) => ({
          ...competitor,
          starttime,
          status,
          computedStatus: (
            <CompetitorStatus
              starttime={starttime}
              status={status}
              finishtime={finishtime}
            />
          ),
          edit: (
            <RaceInfoModal
              updateCompetitor={updateCompetitor}
              name={competitor.name}
              team={competitor.team}
              category={competitor.category}
              status={status}
              starttime={starttime}
              bib={competitor.bib}
              finishtime={finishtime}
              allCategories={allCategories}
            />
          ),
        })
      )
    );
  }, [allCategories, competitors, updateCompetitor]);

  useEffect(() => {
    const filterCompetitors = ({
      name,
      team,
      category,
      bib,
    }: IRaceInfoCompetitor) => {
      return (
        filter === '' ||
        name.toLowerCase().includes(filter) ||
        (team && team.toLowerCase().includes(filter)) ||
        category.toLowerCase().includes(filter) ||
        bib.toString().includes(filter)
      );
    };

    const updateFilteredCompetitors = () =>
      setFilteredCompetitors(
        competitorsWithComputedStatus.filter(filterCompetitors)
      );
    startTimeout(updateFilteredCompetitors, 300);
  }, [competitorsWithComputedStatus, filter, startTimeout]);

  if (!competitors) {
    return <Loader />;
  }

  return (
    <Container className="mt-8 mb-16">
      <div className="flex justify-between">
        <Title accent={raceName}>Kilpailun tiedot</Title>
        <RaceClock />
      </div>
      <div className="flex gap-4 my-4">
        <p>Laitteen kellon epätarkkuus: {timeDiff}ms</p>
        <p>Synkronoinnin tarkkuus: {ping}ms</p>
      </div>

      <div className="flex gap-2 my-4">
        <Button
          className="px-2"
          link={`/ajanotto/${raceToken}/tiedot/virheet`}
          accentColor="plain"
        >
          Virheet
          <Icon className="ml-2" type="external" />
        </Button>
        <Button
          className="px-2"
          link={`/ajanotto/${raceToken}/tiedot/virhe-data`}
          accentColor="plain"
        >
          Virhe data
          <Icon className="ml-2" type="external" />
        </Button>
        <Button
          className="px-2"
          link={`/ajanotto/${raceToken}/tiedot/data`}
          accentColor="plain"
        >
          Data <Icon className="ml-2" type="external" />
        </Button>
      </div>
      <Input
        id="haku"
        value={filter}
        onChange={(e) => setFilter(e.target.value.toLocaleLowerCase())}
      >
        Etsi...
      </Input>

      <Table
        columns={columns}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data={filteredCompetitors as any}
        indexColumnName="bib"
      />
    </Container>
  );
};
