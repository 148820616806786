import React, { useEffect } from 'react';

import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { msToStr } from '../../services/time';

interface IClockProps {
  format?: string;
  interval?: number;
  timeFn?(): number;
}

export const Clock = ({
  format = 'H.MM.SS',
  interval = 1000,
  timeFn = () => Date.now(),
}: IClockProps): JSX.Element => {
  const [startRefresh, stopRefresh] = useAutoRefresh(interval);
  useEffect(() => {
    startRefresh();
    return () => {
      stopRefresh();
    };
  }, [startRefresh, stopRefresh]);

  return <div>{msToStr(timeFn(), format)}</div>;
};
