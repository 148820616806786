import React from 'react';

import { Button } from '../../components/Button/button';
import { Container } from '../../components/container/container';
import { Title } from '../../components/Title/title';
import { useTeamInfoContext } from '../../Context/teamInfoContext';

export const NotFound = (): JSX.Element => {
  const { logourl, name } = useTeamInfoContext();
  return (
    <Container className="my-8">
      <Title className="flex items-center">
        <img src={logourl} alt="Logo" className="h-12 mr-4 sm:h-16" />
        {name}
      </Title>
      <h2 className="my-4 text-xl">Oops! Etsimääsi sivua ei löytynyt</h2>
      <Button link="/" className="mt-8">
        Palaa etusivulle
      </Button>
    </Container>
  );
};
