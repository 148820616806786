import React, { useState, useEffect } from 'react';

import { useTeamInfoContext } from '../Context/teamInfoContext';
import { RaceList } from '../pages/RaceList/raceList';
import { fetchAllRaceInfo } from '../services/apiService';

export const RaceListContainer = (): JSX.Element => {
  const { teamId } = useTeamInfoContext();
  const [raceGroupList, setRaceGroupList] = useState<IRaceInfoWrapper[] | null>(
    null
  );

  useEffect(() => {
    const getRaceList = async () => {
      const raceList = await fetchAllRaceInfo(teamId);
      setRaceGroupList(raceList || null);
    };
    getRaceList();
  }, [teamId]);

  return <RaceList raceGroupList={raceGroupList} />;
};
