import React, { createContext, useContext, useReducer } from 'react';

import {
  EmptyFinishTimeActionType,
  EmptyFinishTimeContextType,
  EmptyFinishTimeType,
} from './emptyFinishTimesContext.types';

export const INITIAL_DATA = 'INITIAL_DATA';
export const ADD_EMPTY_FINISH_TIME = 'ADD_EMPTY_FINISH_TIME';
export const REMOVE_EMPTY_FINISH_TIME = 'REMOVE_EMPTY_FINISH_TIME';

interface IEmptyFinishTimeProviderProps {
  children: React.ReactNode;
}

const initialState: EmptyFinishTimeType = [];

export const EmptyFinishTimeContext = createContext<EmptyFinishTimeContextType>(
  [[], () => null]
);

export const useEmptyFinishTimeValue = (): EmptyFinishTimeContextType =>
  useContext(EmptyFinishTimeContext);

const rootReducer = (
  state: EmptyFinishTimeType,
  action: EmptyFinishTimeActionType
) => {
  switch (action.type) {
    case INITIAL_DATA:
      return state.concat(action.payload);
    case ADD_EMPTY_FINISH_TIME:
      return state.concat(action.payload);
    case REMOVE_EMPTY_FINISH_TIME:
      return state.filter(
        (emptyFinishTime) => emptyFinishTime !== action.payload
      );
    default:
      return state;
  }
};

export const EmptyFinishTimeProvider = ({
  children,
}: IEmptyFinishTimeProviderProps): JSX.Element => (
  <EmptyFinishTimeContext.Provider
    value={useReducer(rootReducer, initialState)}
  >
    {children}
  </EmptyFinishTimeContext.Provider>
);
