import { injectModalConfirm } from '../../components/modal/confirm/modal.confirm';

export interface ICompetitorWithFinishTime {
  buttonLabel: string;
  title: string;
  body: string;
}

export const ConfirmReplaceFinishTime = (
  setNewFinishTime: () => void,
  { buttonLabel, title, body }: ICompetitorWithFinishTime
): void => {
  injectModalConfirm({
    children: body,
    label: title,
    onConfirm: setNewFinishTime,
    submitButtonLabel: buttonLabel,
    modalOpenButtonLabel: buttonLabel,
  });
};
