import React, { useState } from 'react';

import { Container } from '../container/container';

import { NavigationMenu, ImenuItem } from './Navigation.menu';
import { NavigationToggle } from './Navigation.toggle';

interface INavigationProps {
  menuItems: ImenuItem[];
}

export const Navigation = ({ menuItems }: INavigationProps): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className="fixed bottom-0 left-0 w-full h-12 bg-gray-100 z-20 border-t-[1px] border-solid border-gray-200 shadow-inner">
      <Container className="flex justify-end">
        <NavigationToggle isMenuOpen={isMenuOpen} onClick={toggleMenu} />
        <NavigationMenu
          closeMenu={closeMenu}
          menuItems={menuItems}
          isOpen={isMenuOpen}
        />
      </Container>
    </nav>
  );
};
