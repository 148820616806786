import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../components/Button/button';
import { Container } from '../../components/container/container';
import { LinkLongPress } from '../../components/Link/link.long-press';
import { Loader } from '../../components/loader/loader';
import { useTeamInfoContext } from '../../Context/teamInfoContext';
import { useTeamIdQueryString } from '../../hooks/useTeamIdQueryString';

import { RaceListGroup } from './raceList.group';

interface IRaceListProps {
  raceGroupList: IRaceInfoWrapper[] | null;
}

const ALL_RACES_LIST = process.env.REACT_APP_ALL_RACES_LIST || '';

export const RaceList = ({ raceGroupList }: IRaceListProps): JSX.Element => {
  const { msg, logourl, name, teamId } = useTeamInfoContext();
  const queryString = useTeamIdQueryString();

  const history = useHistory();

  const openTiming = () => history.push(`/ajanotto${queryString}`);

  return (
    <Container className="my-4 sm:my-10">
      <h1 className="flex items-center justify-start text-2xl sm:text-5xl">
        <LinkLongPress pressDelay={5000} onLongPress={openTiming}>
          <img src={logourl} alt="Logo" className="h-12 mr-2 sm:mr-4 sm:h-16" />
        </LinkLongPress>
        {name}
      </h1>
      {msg && (
        <p
          className="mt-2 text-lg"
          dangerouslySetInnerHTML={{
            __html: msg.replace(/font-size.*;/i, ''),
          }}
        />
      )}
      {!raceGroupList ? (
        <Loader />
      ) : (
        raceGroupList.map(
          ({ title, races }) =>
            races.length >= 1 && (
              <RaceListGroup key={title} title={title} races={races} />
            )
        )
      )}
      <Button link={ALL_RACES_LIST + teamId}>Näytä kaikki kilpailut</Button>
    </Container>
  );
};
