import React, { createContext, useContext, useReducer } from 'react';

import {
  SET_RACE_ID,
  SET_RACE_TOKEN,
  SET_CONNECTION_STATUS,
  SET_TIME_SYNC,
} from './settingsContext.constants';
import {
  SettingsContextActionType,
  SettingsContextType,
  SettingsStateType,
} from './settingsContext.types';

interface ISettingsProviderProps {
  children: React.ReactNode;
}

const SettingsContext = createContext<SettingsContextType>([{}, () => null]);

const initialState: SettingsStateType = {};

export const useSettings = (): SettingsContextType =>
  useContext(SettingsContext);

const rootReducer = (
  state: SettingsStateType,
  action: SettingsContextActionType
) => {
  switch (action.type) {
    case SET_RACE_ID:
      return { ...state, raceId: action.payload };
    case SET_RACE_TOKEN:
      return { ...state, raceToken: action.payload };
    case SET_CONNECTION_STATUS:
      return { ...state, connectionStatus: action.payload };
    case SET_TIME_SYNC:
      return { ...state, timeSync: action.payload };
    default:
      return state;
  }
};

export const SettingsProvider = ({
  children,
}: ISettingsProviderProps): JSX.Element => (
  <SettingsContext.Provider value={useReducer(rootReducer, initialState)}>
    {children}
  </SettingsContext.Provider>
);
