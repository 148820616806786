import { addLeadingZero } from './utils';

export const msToStr = (totalMs: number, format = 'H:MM:SS.f'): string => {
  // 1- Convert to seconds:
  const ms = totalMs % 1000;
  let seconds = parseInt((totalMs / 1000).toString(), 10);
  // 2- Extract hours:
  let hours = parseInt((seconds / 3600).toString(), 10); // 3,600 seconds in 1 hour
  hours %= 24;
  seconds %= 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt((seconds / 60).toString(), 10); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds %= 60;

  let str = format;
  str = str.replace('HH', addLeadingZero(hours));
  str = str.replace('H', hours.toString());

  str = str.replace('MM', addLeadingZero(minutes));
  str = str.replace('M', minutes.toString());

  str = str.replace('SS', addLeadingZero(seconds));
  str = str.replace('S', seconds.toString());

  str = str.replace('fff', addLeadingZero(ms.toString(), 3));
  str = str.replace('ff', addLeadingZero(Math.round(ms / 100).toString(), 2));
  str = str.replace('f', addLeadingZero(Math.round(ms / 100).toString(), 1));

  return str;
};

export const inputDateFormat = (date: Date): string => {
  const year = date.getFullYear();
  const month = addLeadingZero(date.getMonth() + 1);
  const day = addLeadingZero(date.getDate());

  return `${year}-${month}-${day}`;
};

export const inputTimeFormat = (date: Date): string => {
  const hour = addLeadingZero(date.getHours());
  const minute = addLeadingZero(date.getMinutes());
  const second = addLeadingZero(date.getSeconds());
  const fragments = addLeadingZero(
    Math.round(date.getMilliseconds() / 10).toString(),
    2
  );

  return `${hour}:${minute}:${second}.${fragments}`;
};

export const inputDateTimeFormat = (date: Date): string => {
  return `${inputDateFormat(date)}T${inputTimeFormat(date)}`;
};
