import React, { useState } from 'react';
import { render } from 'react-dom';

import { Modal } from '../modal';
import { ModalActions } from '../modal.actions';

import { ModalConfirmHeader } from './modal.confirm.header';

interface IModalConfirmProps {
  defaultOpen?: boolean;
  toggleButtonClassName?: string;
  accentColor?: 'red' | 'green' | 'brand';
  children?: React.ReactNode;
  label: string;
  modalOpenButtonLabel: string;
  onConfirm(): void;
  onCancel?(): void;
  submitButtonLabel: string;
}

export const ModalConfirm = ({
  defaultOpen = false,
  toggleButtonClassName = '',
  children,
  label,
  modalOpenButtonLabel,
  onConfirm,
  onCancel = () => {},
  submitButtonLabel,
  accentColor,
}: IModalConfirmProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleToggleOpen = () => setIsOpen(!isOpen);

  const handleConfirm = () => {
    onConfirm();
    handleToggleOpen();
  };
  const handleCancel = () => {
    handleToggleOpen();
    onCancel();
  };

  return (
    <Modal
      toggleButtonClassName={toggleButtonClassName}
      modalOpenButtonLabel={modalOpenButtonLabel}
      toggleOpen={handleToggleOpen}
      isOpen={isOpen}
      accentColor={accentColor}
    >
      <ModalConfirmHeader label={label}>{children}</ModalConfirmHeader>
      <ModalActions
        submitButtonLabel={submitButtonLabel}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Modal>
  );
};

export const injectModalConfirm = ({
  children,
  label,
  modalOpenButtonLabel,
  onConfirm,
  onCancel = () => {},
  submitButtonLabel,
  accentColor,
}: IModalConfirmProps): void => {
  const removeThisElement = () =>
    document.getElementById('modal-confirm')?.remove();

  const handleConfirm = () => {
    onConfirm();
    removeThisElement();
  };

  const handleCancel = () => {
    removeThisElement();
    onCancel();
  };

  const targetDiv = document.createElement('div');
  targetDiv.id = 'modal-confirm';
  document.body.appendChild(targetDiv);

  render(
    <ModalConfirm
      children={children}
      label={label}
      modalOpenButtonLabel={modalOpenButtonLabel}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      submitButtonLabel={submitButtonLabel}
      accentColor={accentColor}
      toggleButtonClassName="hidden"
      defaultOpen={true}
    />,
    targetDiv
  );
};
