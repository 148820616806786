import React, { useState } from 'react';

import { Button } from '../../components/Button/button';
import { Container } from '../../components/container/container';
import { Input } from '../../components/input/input';
import { Loader } from '../../components/loader/loader';
import { Title } from '../../components/Title/title';

import { StartListCategory } from './startList.category';

interface IStartListProps {
  startList: ICompetitor[] | null;
  categories: ICategory[] | null;
  raceName: string;
}

export const StartList = ({
  startList,
  categories,
  raceName,
}: IStartListProps): JSX.Element => {
  const [filter, setFilter] = useState('');

  const filterStartList = ({ name, team, category, bib }: ICompetitor) =>
    filter === '' ||
    name.toLowerCase().includes(filter.toLowerCase()) ||
    (team && team.toLowerCase().includes(filter.toLowerCase())) ||
    category.toLowerCase().includes(filter.toLowerCase()) ||
    bib.toString().includes(filter);

  const filteredStarList =
    startList != null ? startList.filter(filterStartList) : [];

  return (
    <Container className="my-4 sm:my-10">
      <Title accent={raceName}>Lähtölista</Title>

      {!categories || !startList ? (
        <Loader />
      ) : categories &&
        startList &&
        (categories.length === 0 || startList.length === 0) ? (
        <h2 className="text-2xl my-4">Kilpailun lähtölistaa ei löytynyt</h2>
      ) : (
        <>
          <Input
            id="haku"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            Etsi...
          </Input>

          {categories.map(({ key, name }) => (
            <StartListCategory
              key={key}
              id={key}
              startList={filteredStarList.filter(
                (competitor) => competitor.category === key
              )}
            >
              {name}
            </StartListCategory>
          ))}
        </>
      )}
      <Button link="/">Takaisin</Button>
    </Container>
  );
};
