import React from 'react';

import { IRootColumn, Table } from '../../components/Table/table';
import {
  CATEGORY_CLASSES,
  NAME_CLASSES,
  NUMBER_CLASSES,
  TIME_CLASSES,
} from '../../components/Table/table.constants';
import { msToStr } from '../../services/time';

export interface ICompetitorWithPositionResult extends ICompetitorWithPosition {
  result: number | React.ReactNode;
}

interface IResultsCategoryProps {
  results: ICompetitorWithPositionResult[];
  category: ICategory;
  titleClassName?: string;
}

const columns: IRootColumn[] = [
  {
    key: 'position',
    title: 'Sija',
    widthClassName: NUMBER_CLASSES,
  },
  {
    key: 'bib',
    title: 'Nro',
    widthClassName: NUMBER_CLASSES,
  },
  {
    key: 'name',
    title: 'Nimi',
    widthClassName: NAME_CLASSES,
    accent: { key: 'team', title: 'Seura' },
  },
  {
    key: 'category',
    title: 'Sarja',
    widthClassName: CATEGORY_CLASSES,
  },
  {
    key: 'result',
    title: 'Aika',
    widthClassName: TIME_CLASSES,
  },
  {
    key: 'timeDiff',
    title: 'Ero',
    widthClassName: TIME_CLASSES,
    formatter: (timeDiff) =>
      timeDiff === null
        ? ''
        : msToStr(
            typeof timeDiff === 'number' ? timeDiff : parseInt(timeDiff, 10),
            'H.MM.SS,f'
          ),
  },
];

export const ResultsCategory = ({
  results,
  category,
  titleClassName = '',
}: IResultsCategoryProps): JSX.Element => {
  const visibleCategoryName =
    typeof category.name !== 'undefined' && category.name.length > 0
      ? category.name
      : category.key;
  return (
    <div className="my-4">
      <h2
        id={category.key}
        className={`pt-10 mb-2 -mt-10 text-xl sm:text-3xl ${titleClassName}`}
      >
        {visibleCategoryName}
      </h2>
      <Table
        columns={columns}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data={results as any}
        indexColumnName="bib"
      />
    </div>
  );
};
