import React, { useState, useEffect, useCallback } from 'react';

import { Button } from '../../components/Button/button';
import { RaceClock } from '../../components/Clock/RaceClock';
import { Container } from '../../components/container/container';
import { Icon } from '../../components/Icon/icon';
import { Loader } from '../../components/loader/loader';
import { Title } from '../../components/Title/title';
import { useSetTimeout } from '../../hooks/useSetTimeout';

import { FinishItem } from './finish.item';

interface IFinishProps {
  addEmptyFinishTime(): void;
  onTrack: ICompetitor[] | null;
  setFinish(bib: number): void;
  removeFinish(bib: number): void;
  raceId: string;
}

const onTrackFilter = (competitor: ICompetitor) =>
  (competitor.status === '' || competitor.status === undefined) &&
  competitor.finishtime === 0;

export const Finish = ({
  addEmptyFinishTime,
  onTrack,
  setFinish,
  removeFinish,
  raceId,
}: IFinishProps): JSX.Element => {
  const [visibleBibs, setVisibleBibs] = useState<number[]>([]);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [startTimeout] = useSetTimeout();

  const updateList = useCallback(() => {
    const newVisibleBibs =
      onTrack?.filter(onTrackFilter).map((competitor) => competitor.bib) || [];
    setVisibleBibs(newVisibleBibs);
    setIsUpdateAvailable(false);
  }, [onTrack]);

  useEffect(() => {
    if (visibleBibs.length === 0) {
      startTimeout(updateList, 500);
    }
  }, [startTimeout, updateList, visibleBibs.length]);

  useEffect(() => {
    if (visibleBibs.length === 0 || !onTrack) {
      return;
    }
    setIsUpdateAvailable(
      !onTrack
        .filter(onTrackFilter)
        .every((competitor) => visibleBibs.includes(competitor.bib))
    );
  }, [onTrack, updateList, visibleBibs]);

  const handleAddEmptyFinishTime = () => {
    try {
      window.navigator.vibrate(200);
    } finally {
      addEmptyFinishTime();
    }
  };

  if (!onTrack) {
    return <Loader />;
  }

  return (
    <Container className="mt-4 mb-12">
      <div className="flex justify-between">
        <Title> Aseta maaliaika</Title>
        <RaceClock />
      </div>
      <div className="grid grid-cols-3 gap-2 my-4">
        <Button
          onClick={handleAddEmptyFinishTime}
          accentColor="green"
          className="px-2"
        >
          + Maali
        </Button>
        <Button link={`/ajanotto/${raceId}/maali/maaliajat`}>Maaliajat</Button>
        <Button
          onClick={updateList}
          accentColor={isUpdateAvailable ? 'green' : 'brand'}
        >
          <Icon type="reload" />
          {isUpdateAvailable && ' *'}
        </Button>
      </div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(90px,1fr))] gap-4 h-[calc(100vh-186px)] overflow-x-auto pb-2 box-border">
        {onTrack.length ? (
          onTrack
            .filter(({ bib }) => visibleBibs.includes(bib))
            .map(({ bib, finishtime }) => (
              <FinishItem
                key={bib}
                bib={bib}
                hasCompetitorFinishTime={Boolean(finishtime && finishtime > 0)}
                onClick={
                  finishtime === 0
                    ? () => setFinish(bib)
                    : () => removeFinish(bib)
                }
              />
            ))
        ) : (
          <h2 className="text-xl">
            Yksikään kilpailija ei ole matkalla tällä hetkellä
          </h2>
        )}
      </div>
    </Container>
  );
};
