import React, { useState, useEffect } from 'react';

import { Button } from '../../components/Button/button';
import { RaceClock } from '../../components/Clock/RaceClock';
import { Container } from '../../components/container/container';
import { Loader } from '../../components/loader/loader';
import { Title } from '../../components/Title/title';
import { msToStr } from '../../services/time';

import { ConfirmReplaceFinishTime } from './emptyFinishTime.confirm';
import { ICompetitorWithFinishTime } from './emptyFinishTime.confirm';
import { EmptyFinishTimeItem } from './emptyFinishTime.item';

interface IFinishTimeProps {
  emptyFinishTimes: number[];
  addEmptyFinishTime(): void;
  removeEmptyFinishTime(finishTime: number): void;
  setFinishTime(bib: number, finishTime: number): void;
  competitorsWithFinishTime: ICompetitor[] | null;
  availableBibs: number[] | null;
  raceId: string | number;
}

interface IVisibleTimes {
  finishTime: number;
  bib?: number;
}

export const EmptyFinishTime = ({
  emptyFinishTimes,
  addEmptyFinishTime,
  removeEmptyFinishTime,
  setFinishTime,
  competitorsWithFinishTime,
  availableBibs,
  raceId,
}: IFinishTimeProps): JSX.Element => {
  const [showAllTimes, setShowAllTimes] = useState(false);
  const [visibleTimes, setVisibleTimes] = useState<IVisibleTimes[]>([]);

  useEffect(() => {
    if (!competitorsWithFinishTime) {
      return;
    }

    let newVisibleBibs = emptyFinishTimes.map<IVisibleTimes>((time) => ({
      finishTime: time,
    }));
    if (showAllTimes) {
      const competitorFinishTimes =
        competitorsWithFinishTime.map<IVisibleTimes>(
          ({ bib, finishtime: finishTime = 0 }) => ({ bib, finishTime })
        );

      newVisibleBibs = [...newVisibleBibs, ...competitorFinishTimes];
    }

    setVisibleTimes(newVisibleBibs.sort((a, b) => a.finishTime - b.finishTime));
  }, [competitorsWithFinishTime, emptyFinishTimes, showAllTimes]);

  const toggleShowAllTimes = () => setShowAllTimes(!showAllTimes);

  const handleSetFinishTimeSubmit = (
    event: React.FormEvent<EventTarget>,
    finishTime: number
  ) => {
    event.preventDefault();
    const { bib } = event.target as unknown as { bib: HTMLInputElement };
    const bibValue = parseInt(bib.value, 10);

    if (!availableBibs?.includes(bibValue)) {
      const modalData: ICompetitorWithFinishTime = {
        title: 'Kilpailijaa ei löytynyt.',
        body: `Kilpailijaa numerolla ${bibValue} ei löytynyt. Haluatko tästä huolimatta asettaa kilpailijalle maaliajan ${msToStr(
          finishTime
        )}?`,
        buttonLabel: 'Aseta',
      };
      ConfirmReplaceFinishTime(
        () => setFinishTime(bibValue, finishTime),
        modalData
      );
      return;
    }

    const existingResult = competitorsWithFinishTime?.find(
      (i) => i.bib === bibValue
    );

    if (typeof existingResult !== 'undefined') {
      const modalData: ICompetitorWithFinishTime = {
        body: `Korvataanko kilpailijan ${
          existingResult.bib
        } maaliaika ${msToStr(
          existingResult.finishtime ?? 0
        )} uudella ${msToStr(finishTime)}?`,
        buttonLabel: 'Korvaa',
        title: 'Kilpailijalla on jo maaliaika.',
      };
      ConfirmReplaceFinishTime(
        () => setFinishTime(bibValue, finishTime),
        modalData
      );
      return;
    }

    setFinishTime(bibValue, finishTime);
  };

  if (!competitorsWithFinishTime || !availableBibs) {
    return <Loader />;
  }

  return (
    <Container className="mt-4 mb-12">
      <div className="flex justify-between my-4">
        <Title>Tyhjät maaliajat</Title>
        <RaceClock />
      </div>
      <div className="grid grid-cols-3 gap-2 my-4">
        <Button
          onClick={addEmptyFinishTime}
          accentColor="green"
          className="px-3"
        >
          + Maali
        </Button>
        <Button link={`/ajanotto/${raceId}/maali`} className="px-3">
          Maaliin
        </Button>
        <Button
          accentColor={showAllTimes ? 'brand' : 'plain'}
          onClick={toggleShowAllTimes}
          className="px-3"
        >
          Kaikki
        </Button>
      </div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(270px,1fr))] gap-y-4 gap-x-16 my-8">
        {visibleTimes.map(({ finishTime, bib }) => (
          <EmptyFinishTimeItem
            key={`${finishTime}-${bib}`}
            finishTime={finishTime}
            handleSetFinishTimeSubmit={(event: React.FormEvent<EventTarget>) =>
              handleSetFinishTimeSubmit(event, finishTime)
            }
            removeEmptyFinishTime={() => removeEmptyFinishTime(finishTime)}
            bib={bib}
          />
        ))}
      </div>
    </Container>
  );
};
