import { Loader } from '../loader/loader';

export interface IColumnBaseFields {
  key: string;
  title: string;
  titleClassName?: string;
  className?: string;
  firstRowClassName?: string;
  formatter?(value: number | string): string;
}

export interface IRootColumn extends IColumnBaseFields {
  accent?: IColumnBaseFields;
  widthClassName: string;
}

interface ITableProps {
  columns: IRootColumn[];
  data: { [key in string]: string | number }[] | null;
  noRowsMessage?: string;
  indexColumnName: string;
  className?: string;
}

const COMMON_ROW_CLASSES = 'flex justify-between items-center gap-2 w-full p-2';
const TITLE_ROW_CLASSES = 'text-white bg-brand-color rounded-t-sm';
const BODY_ROW_COLOR_CLASSES =
  'bg-gray-100 hover:bg-gray-200 even:bg-gray-50 hover:even:bg-gray-100 transition-colors';

export const Table = ({
  columns,
  data,
  indexColumnName,
  noRowsMessage = 'Rivejä ei löytynyt',
  className = '',
}: ITableProps): JSX.Element => {
  const getTableBody = () => {
    if (data === null) {
      return (
        <tr
          className={`flex justify-center w-full p-2 ${BODY_ROW_COLOR_CLASSES} rounded-t-sm`}
        >
          <td className="w-3/4 max-w-sm">
            <Loader />
          </td>
        </tr>
      );
    } else if (data.length <= 0) {
      return (
        <tr
          className={`${COMMON_ROW_CLASSES} ${BODY_ROW_COLOR_CLASSES} rounded-t-sm`}
        >
          <td>{noRowsMessage}</td>
        </tr>
      );
    }

    return data.map(({ highlight, passive, ...row }, rowNumber) => (
      <tr
        key={row[indexColumnName]}
        className={`${COMMON_ROW_CLASSES} rounded-t-sm ${
          highlight ? 'bg-yellow-500 text-white' : BODY_ROW_COLOR_CLASSES
        } ${
          passive && !highlight
            ? 'relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-gray-100 after:opacity-70'
            : ''
        } `}
      >
        {columns.map(
          ({
            key,
            widthClassName,
            className: rowClassName = '',
            firstRowClassName = '',
            accent,
            formatter,
          }) => (
            <td
              key={key}
              className={`${widthClassName} ${rowClassName} ${
                rowNumber === 0 ? firstRowClassName : ''
              }`}
            >
              {formatter ? formatter(row[key]) : row[key]}
              {accent && (
                <p
                  className={`text-sm ${
                    !highlight ? 'text-gray-500' : 'text-white'
                  }`}
                >
                  {row[accent.key]}
                </p>
              )}
            </td>
          )
        )}
      </tr>
    ));
  };

  return (
    <div className="overflow-x-auto">
      <table
        className={`w-full text-left my-4 shadow-sm rounded-sm ${className}`}
      >
        <thead>
          <tr className={`${COMMON_ROW_CLASSES} ${TITLE_ROW_CLASSES}`}>
            {columns.map(
              ({ key, title, widthClassName, titleClassName = '', accent }) => (
                <th key={key} className={`${widthClassName} ${titleClassName}`}>
                  {title}
                  {accent && <p className="text-sm">{accent.title}</p>}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>{getTableBody()}</tbody>
      </table>
    </div>
  );
};
