import { useState, useEffect, useCallback } from 'react';

export const useSetTimeout = (): [
  startTimeout: (targetFunction: () => void, delay: number) => void,
  removeTimeout: () => void
] => {
  const [timeoutCache, setTimeoutCache] = useState<NodeJS.Timeout>();

  const removeTimeout = useCallback(() => {
    if (timeoutCache) clearTimeout(timeoutCache);
  }, [timeoutCache]);

  const startTimeout = useCallback(
    (targetFunction: () => void, delay: number) => {
      setTimeoutCache(setTimeout(targetFunction, delay));
    },
    []
  );

  useEffect(
    () => () => {
      removeTimeout();
    },
    [removeTimeout]
  );

  return [startTimeout, removeTimeout];
};

export const useSetMultipleTimeout = (): [
  startNewTimeout: (targetFunction: () => void, delay: number) => void,
  removeAllTimeouts: () => void
] => {
  const [, setTimeoutCache] = useState<NodeJS.Timeout[]>([]);

  const removeAllTimeouts = useCallback(() => {
    setTimeoutCache((cache) => {
      cache.forEach((timeout) => clearTimeout(timeout));
      return [];
    });
  }, []);

  const startNewTimeout = useCallback(
    (targetFunction: () => void, delay: number) => {
      setTimeoutCache((cache) => [...cache, setTimeout(targetFunction, delay)]);
    },
    []
  );

  useEffect(
    () => () => {
      removeAllTimeouts();
    },
    [removeAllTimeouts]
  );

  return [startNewTimeout, removeAllTimeouts];
};
