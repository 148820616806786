import { useCallback } from 'react';

import { useSettings } from '../Context/settings/settingsContext';
import { SET_TIME_SYNC } from '../Context/settings/settingsContext.constants';
import { getTimeSyncInfo } from '../services/apiService';

export const useUpdateTimeSync = (): (() => void) => {
  const [, dispatch] = useSettings();

  return useCallback(() => {
    const syncTime = async (count = 0, lowestPing = NaN) => {
      const { timeDiff, ping } = await getTimeSyncInfo();

      if (isNaN(lowestPing) || ping < lowestPing) {
        dispatch({ type: SET_TIME_SYNC, payload: { timeDiff, ping } });
      }

      if (count < 10 && ping > 100) {
        const newLowestPing = ping < lowestPing ? ping : lowestPing;
        setTimeout(syncTime, 1000, count + 1, newLowestPing);
      }
    };

    syncTime();
  }, [dispatch]);
};
