import {
  SET_FINISH_TIME,
  useCompetitorValue,
} from '../../Context/competitorContext';
import {
  useEmptyFinishTimeValue,
  ADD_EMPTY_FINISH_TIME,
  REMOVE_EMPTY_FINISH_TIME,
} from '../../Context/emptyFinishTimeContext';
import { useSettings } from '../../Context/settings/settingsContext';
import {
  useEmitAndDispatchEmptyFinishTime,
  useEmitAndDispatchCompetitors,
} from '../../Context/socketContext.helper';
import { useRaceUnixtime } from '../../hooks/useRaceTime';
import { EmptyFinishTime } from '../../pages/EmptyFinishTime/emptyFinishTime';

export const EmptyFinishTimesContainer = (): JSX.Element => {
  const getRaceTime = useRaceUnixtime();
  const [{ competitors }] = useCompetitorValue();
  const [emptyFinishTimes] = useEmptyFinishTimeValue();
  const dispatchEmptyFinishTime = useEmitAndDispatchEmptyFinishTime();
  const dispatchCompetitor = useEmitAndDispatchCompetitors();
  const [{ raceToken }] = useSettings();

  const addEmptyFinishTime = () =>
    dispatchEmptyFinishTime({
      type: ADD_EMPTY_FINISH_TIME,
      payload: getRaceTime(),
    });
  const removeEmptyFinishTime = (finishTime: number) =>
    dispatchEmptyFinishTime({
      type: REMOVE_EMPTY_FINISH_TIME,
      payload: finishTime,
    });
  const setFinishTime = (bib: number, finishTime: number) => {
    dispatchCompetitor({
      type: SET_FINISH_TIME,
      payload: { bib, finishTime },
    });
    removeEmptyFinishTime(finishTime);
  };
  const competitorsWithFinishTime =
    competitors?.filter(
      (competitor: ICompetitor) =>
        competitor.finishtime && competitor.finishtime > 0
    ) || null;

  return (
    <EmptyFinishTime
      emptyFinishTimes={emptyFinishTimes.filter((i) => i !== 0)}
      addEmptyFinishTime={addEmptyFinishTime}
      removeEmptyFinishTime={removeEmptyFinishTime}
      setFinishTime={setFinishTime}
      competitorsWithFinishTime={competitorsWithFinishTime}
      availableBibs={competitors?.map(({ bib }) => bib) ?? null}
      raceId={raceToken || ''}
    />
  );
};
