import React, { FormEvent } from 'react';

import { Input } from '../../components/input/input';
import {
  RadioButton,
  RadioButtonGroup,
} from '../../components/input/radioButton';
import { Select, IOption } from '../../components/input/select';
import { ModalForm } from '../../components/modal/form/modal.confirm';
import { SET_DETAILS } from '../../Context/competitorContext';
import {
  CompetitorContextEmitableActionType,
  CompetitorContextSetDetailsActionType,
} from '../../Context/competitorContext.types';
import {
  inputDateFormat,
  inputDateTimeFormat,
  inputTimeFormat,
} from '../../services/time';
import { roundToTens, roundToThousands } from '../../services/utils';

import {
  ChangedField,
  confirmUpdateModal,
} from './raceInfo.confirmUpdateModal';

interface FormSubmitFields {
  bib: HTMLInputElement;
  name: HTMLInputElement;
  team: HTMLInputElement;
  startTime: HTMLInputElement;
  finishTime: HTMLInputElement;
  category: HTMLInputElement;
  status: HTMLInputElement[];
}

const EMPTY_TIME_INPUT = '00:00:00.000';
const isEmptyTimeInput = (value: string) =>
  value === EMPTY_TIME_INPUT || value === '';

interface IRaceInfoModalProps extends ICompetitor {
  updateCompetitor(dispatchData: CompetitorContextEmitableActionType): void;
  allCategories: ICategory[];
}

const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60 * 1000;

export const RaceInfoModal = ({
  bib,
  name,
  starttime,
  finishtime = 0,
  team = '',
  category,
  status,
  updateCompetitor,
  allCategories,
}: IRaceInfoModalProps): JSX.Element => {
  const handleUpdateCompetitorSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const START_DATE = inputDateFormat(new Date(starttime + TIMEZONE_OFFSET));
    const {
      bib: bibInput,
      name: nameInput,
      team: teamInput,
      startTime: startTimeInput,
      finishTime: finishTimeInput,
      category: categoryInput,
      status: statusOptions,
    } = e.target as unknown as FormSubmitFields;
    const newStatus = [...statusOptions].find(({ checked }) => checked);
    const newBib = parseInt(bibInput.value);
    const newName = nameInput.value;
    const newTeam = teamInput.value;
    const newCategory = categoryInput.value;
    const newStartTime = new Date(START_DATE + ' ' + startTimeInput.value);
    const newFinishTime = new Date(START_DATE + ' ' + finishTimeInput.value);

    const changedFields: ChangedField[] = [];

    const updatedCompetitorDetails: CompetitorContextSetDetailsActionType['payload'] =
      {
        bib,
        status,
        starttime,
        finishtime,
        name,
        team,
        category,
      };

    if (bib !== newBib) {
      changedFields.push({
        fieldName: 'bib',
        oldValue: bib.toString(),
        newValue: newBib.toString(),
      });
    }
    if (name !== newName) {
      changedFields.push({
        fieldName: 'name',
        oldValue: name,
        newValue: newName,
      });
      updatedCompetitorDetails.name = newName;
    }

    if (team !== newTeam) {
      changedFields.push({
        fieldName: 'team',
        oldValue: team,
        newValue: newTeam,
      });
      updatedCompetitorDetails.team = newTeam;
    }
    if (
      roundToThousands(starttime + TIMEZONE_OFFSET) !==
      roundToThousands(newStartTime.getTime())
    ) {
      changedFields.push({
        fieldName: 'startTime',
        oldValue: inputDateTimeFormat(
          new Date(starttime + TIMEZONE_OFFSET)
        ).replace('T', ' '),
        newValue: inputDateTimeFormat(newStartTime).replace('T', ' '),
      });
      updatedCompetitorDetails.starttime =
        newStartTime.getTime() - TIMEZONE_OFFSET;
    }

    const isNewFinishtimeEmpty =
      isEmptyTimeInput(finishTimeInput.value) || isNaN(newFinishTime.getTime());

    if (
      (roundToTens(finishtime + TIMEZONE_OFFSET) !==
        roundToTens(newFinishTime.getTime()) &&
        !isNewFinishtimeEmpty) ||
      (finishtime > 0 && isNewFinishtimeEmpty)
    ) {
      changedFields.push({
        fieldName: 'finishTime',
        oldValue:
          finishtime > 0
            ? inputDateTimeFormat(
                new Date(finishtime + TIMEZONE_OFFSET)
              ).replace('T', ' ')
            : '-',
        newValue: !isNewFinishtimeEmpty
          ? inputDateTimeFormat(newFinishTime).replace('T', ' ')
          : '-',
      });
      updatedCompetitorDetails.finishtime = !isNewFinishtimeEmpty
        ? newFinishTime.getTime() - TIMEZONE_OFFSET
        : 0;
    }
    if (category !== newCategory) {
      changedFields.push({
        fieldName: 'category',
        oldValue: category,
        newValue: newCategory,
      });
      updatedCompetitorDetails.category = newCategory;
    }
    if (
      typeof newStatus?.value !== 'undefined' &&
      status !== newStatus?.value
    ) {
      const newStatusValue = newStatus?.value as CompetitorStatusType;
      changedFields.push({
        fieldName: 'status',
        oldValue: status === '' ? 'Paikalla' : status.toUpperCase(),
        newValue:
          newStatusValue === ''
            ? 'Paikalla'
            : newStatusValue.toLocaleUpperCase(),
      });
      updatedCompetitorDetails.status = newStatusValue;
    }

    const updateChangedCompetitorChangedFields = () =>
      updateCompetitor({
        type: SET_DETAILS,
        payload: updatedCompetitorDetails,
      });

    if (changedFields.length > 0) {
      confirmUpdateModal(
        changedFields,
        bib,
        name,
        updateChangedCompetitorChangedFields
      );
    }
  };
  return (
    <ModalForm
      label={`Kilpailijan ${name} tiedot`}
      modalOpenButtonLabel="Muokkaa"
      onSubmit={handleUpdateCompetitorSubmit}
      submitButtonLabel={'Tallenna'}
      toggleButtonClassName="px-4"
    >
      <Input id="bib" children="Numero" value={bib} isDisabled />
      <Input id="name" children="Nimi" value={name} />
      <Input id="team" children="Seura" value={team} />
      <Input
        id="startTime"
        children="Lähtöaika"
        type="time"
        value={
          starttime
            ? inputTimeFormat(new Date(starttime + TIMEZONE_OFFSET))
            : ''
        }
        step={1}
      />
      <Input
        id="finishTime"
        children="Maaliaika"
        type="time"
        value={
          finishtime
            ? inputTimeFormat(new Date(finishtime + TIMEZONE_OFFSET))
            : ''
        }
        step={0.01}
      />

      <Select
        children="Sarja"
        id="category"
        defaultValue={category}
        options={allCategories.map<IOption>(({ key, name: categoryName }) => ({
          value: key,
          label: categoryName || key,
        }))}
      />

      <RadioButtonGroup label="Status">
        <RadioButton checked={status === ''} id="status" value="">
          Paikalla
        </RadioButton>
        <RadioButton checked={status === 'DNS'} id="status" value="DNS">
          DNS
        </RadioButton>
        <RadioButton checked={status === 'DNF'} id="status" value="DNF">
          DNF
        </RadioButton>
      </RadioButtonGroup>
    </ModalForm>
  );
};
