import React from 'react';

import { IconCode } from './icon.code';
import { IconDownload } from './icon.download';
import { IconExcel } from './icon.excel';
import { IconExternal } from './icon.external';
import { IconPdf } from './icon.pdf';
import { IconReload } from './icon.reload';

export type IconType =
  | 'download'
  | 'pdf'
  | 'code'
  | 'excel'
  | 'external'
  | 'reload';

interface IIconProps {
  type: IconType;
  className?: string;
}

export const Icon = ({ type, className = '' }: IIconProps): JSX.Element => {
  switch (type) {
    case 'pdf':
      return <IconPdf className={className} />;
    case 'code':
      return <IconCode className={className} />;
    case 'excel':
      return <IconExcel className={className} />;
    case 'external':
      return <IconExternal className={className} />;
    case 'reload':
      return <IconReload className={className} />;

    default:
      return <IconDownload className={className} />;
  }
};
