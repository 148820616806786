import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { fetchTeamInfo } from '../services/apiService';

interface ITeamInfoProviderProps {
  children: React.ReactNode;
}

export interface TeamInfoContextType extends ITeamInfo {
  teamId: number;
}

const SITE_BASE_PATH = process.env.PUBLIC_URL || '';
const TEAM_NAME = process.env.REACT_APP_TEAM_NAME || '';
const TEAM_ICON = process.env.REACT_APP_TEAM_ICON || '';
const TEAM_LOGO = SITE_BASE_PATH + TEAM_ICON;
const BRAND_COLOR = process.env.REACT_APP_TEAM_BRAND_COLOR || '';
const TEAM_WEBSITE = process.env.REACT_APP_TEAM_WEBSITE || '';
const TEAM_ADDITIONAL_NOTE = process.env.REACT_APP_TEAM_ADDITIONAL_NOTE || '';
const TEAM_ID = parseInt(process.env.REACT_APP_TEAM_ID || '', 10);

const initialState: TeamInfoContextType = {
  name: TEAM_NAME,
  logourl: TEAM_LOGO,
  www: TEAM_WEBSITE,
  msg: TEAM_ADDITIONAL_NOTE,
  color: BRAND_COLOR,
  teamId: TEAM_ID,
};

export const TeamInfoContext = createContext<TeamInfoContextType>(initialState);

export const useTeamInfoContext = (): TeamInfoContextType =>
  useContext(TeamInfoContext);

export const TeamInfoProvider = ({
  children,
}: ITeamInfoProviderProps): JSX.Element => {
  const { search } = useLocation();
  const [teamInfo, setTeamInfo] = useState(initialState);

  const urlSearchParams = new URLSearchParams(search);
  const { teamId } = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    const updateTeamInfo = async (newTeamId: number) => {
      const { logourl, ...teamInfoResponse } = await fetchTeamInfo(newTeamId);
      setTeamInfo({
        ...teamInfoResponse,
        teamId: newTeamId,
        logourl: newTeamId === TEAM_ID ? TEAM_LOGO : logourl,
      });
    };

    const parsedTeamId = parseInt(teamId, 10) || TEAM_ID;
    setTeamInfo((oldTeamInfo) => ({ ...oldTeamInfo, teamId: parsedTeamId }));

    updateTeamInfo(parsedTeamId);
  }, [teamId]);

  useEffect(() => {
    document.documentElement.style.setProperty('--brand-color', teamInfo.color);
  }, [teamInfo.color]);

  return (
    <TeamInfoContext.Provider value={teamInfo}>
      {children}
    </TeamInfoContext.Provider>
  );
};
