import { useCallback } from 'react';

import { useRaceUnixtime } from '../../hooks/useRaceTime';

export const useTimeToStart = (): ((startTime: number) => number) => {
  const getRaceTime = useRaceUnixtime();

  return useCallback(
    (startTime: number) => startTime - getRaceTime(),
    [getRaceTime]
  );
};
