import React from 'react';

import { IRootColumn, Table } from '../../components/Table/table';
import { msToStr } from '../../services/time';

interface IStartListCategoryProps {
  children: React.ReactNode;
  startList: ICompetitor[];
  id: string;
}

const columns: IRootColumn[] = [
  {
    key: 'starttime',
    title: 'Lähtöaika',
    widthClassName: 'w-1/6',
    formatter: (startTime) =>
      msToStr(
        typeof startTime === 'number' ? startTime : parseInt(startTime, 10),
        'HH.MM.SS'
      ),
  },
  {
    key: 'bib',
    title: 'Nro',
    widthClassName: 'w-1/6',
  },
  {
    key: 'name',
    title: 'Nimi',
    widthClassName: 'w-5/12',
    accent: { key: 'team', title: 'Seura' },
  },
  {
    key: 'category',
    title: 'Sarja',
    widthClassName: 'w-1/6',
  },
];

export const StartListCategory = ({
  startList,
  children,
  id,
}: IStartListCategoryProps): JSX.Element =>
  startList.length > 0 ? (
    <div className="my-4" id={id}>
      <h2 className="text-xl sm:text-3xl my-2">{children}</h2>
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data={startList as any}
          indexColumnName="bib"
          className="min-w-[30rem]"
        />
      </div>
    </div>
  ) : (
    <></>
  );
