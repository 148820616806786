import React from 'react';

interface INavigationToggleProps {
  isMenuOpen: boolean;
  onClick(): void;
}

const HAMBURGER_CLASSES =
  'block absolute h-[3px] w-[1.666rem] bg-black transform transition duration-150 ease-in-out';

const NavigationToggle = ({
  isMenuOpen,
  onClick,
}: INavigationToggleProps): JSX.Element => (
  <button
    className="text-gray-500 w-12 h-12 relative focus:outline-none "
    onClick={onClick}
  >
    <span className="sr-only">Open main menu</span>
    <div className="block w-7 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
      <span
        aria-hidden="true"
        className={`${HAMBURGER_CLASSES} ${
          isMenuOpen ? 'rotate-45' : '-translate-y-2'
        }`}
      ></span>
      <span
        aria-hidden="true"
        className={`${HAMBURGER_CLASSES} ${isMenuOpen ? 'opacity-0' : ''}`}
      ></span>
      <span
        aria-hidden="true"
        className={`${HAMBURGER_CLASSES} ${
          isMenuOpen ? '-rotate-45' : 'translate-y-2'
        }`}
      ></span>
    </div>
  </button>
);

export { NavigationToggle };
