import React from 'react';

export type StatusType = 'GOOD' | 'WARNING' | 'DANGER';

interface IStatusBarProps {
  status: StatusType;
}

const getStatusColorClasses = (status: StatusType) => {
  switch (status) {
    case 'GOOD':
      return 'bg-green-500';
    case 'DANGER':
      return 'bg-red-600';
    default:
      return 'bg-yellow-500';
  }
};

export const StatusColorBar = ({ status }: IStatusBarProps): JSX.Element => (
  <div
    className={`fixed top-0 left-0 h-4 w-full shadow-md transition-colors ${getStatusColorClasses(
      status
    )}`}
  />
);
