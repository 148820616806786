import React from 'react';

export interface ICategoryNavigationItemProps {
  name: string;
  highlight?: boolean;
}

export const CategoryNavigationItem = ({
  name,
  highlight = false,
}: ICategoryNavigationItemProps): JSX.Element => (
  <li
    className={`py-2 transition-colors border-solid border-gray-300 border-t-[1px] border-b-[1px] border-l-[1px] last-of-type:border-r-[1px] ${
      highlight
        ? 'bg-yellow-500 text-white'
        : 'bg-gray-100 hover:bg-gray-200 even:bg-gray-50 hover:even:bg-gray-100'
    }`}
  >
    <a className="py-2 px-4" href={`#${name}`}>
      {name}
    </a>
  </li>
);
