import React from 'react';

import { Button } from '../../components/Button/button';
import { ButtonLongPress } from '../../components/Button/button.long-press';

interface IFinishItemProps {
  bib: number;
  hasCompetitorFinishTime: boolean;
  onClick(): void;
}

export const FinishItem = ({
  bib,
  hasCompetitorFinishTime,
  onClick,
}: IFinishItemProps): JSX.Element => {
  if (!hasCompetitorFinishTime) {
    return (
      <Button onClick={onClick} className="text-lg select-none">
        {bib}
      </Button>
    );
  }
  return (
    <ButtonLongPress
      isVisiallyDisabled
      onLongPress={onClick}
      className="text-lg cursor-pointer select-none"
    >
      {bib}
    </ButtonLongPress>
  );
};
